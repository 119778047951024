import { useLoadContext } from "../../../contexts/LoadContext";
import ScreenLoaderView from "./ScreenLoaderView";

function ScreenLoader({ groups }) {
  const loadContext = useLoadContext();

  if (loadContext.isLoading(groups)) {
    return <ScreenLoaderView />;
  }
}

export default ScreenLoader;
