import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledAltIconButton } from "../../styles/InputStyles";
import {
  StyledCreateButton,
  StyledUserCard,
  StyledUserName,
  StyledUserRole,
  StyledUsersList,
} from "./UsersPageStyles";
import { faPencil, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { StyledFlexItem, StyledFlexRow } from "../../styles/LayoutStyles";
import { useTheme } from "styled-components";
import HiddenLoader from "../../components/loader/hidden/HiddenLoader";
import { StyledDashboardContent } from "../../layouts/dashboard/DashboardLayoutStyles";
import useUserCreateRouteConfig from "../../routes/configs/pages/users/UserCreateRouteConfig";
import useUserEditRouteConfig from "../../routes/configs/pages/users/UserEditRouteConfig";
import LanguageHelper from "../../helpers/LanguageHelper";

function UsersPageView({
  users,
  onCreateClick,
  onEditClick,
  onDeleteClick,
  deleteDisabled,
}) {
  const theme = useTheme();
  const userCreateRouteConfig = useUserCreateRouteConfig();
  const userEditRouteConfig = useUserEditRouteConfig();

  return (
    <StyledDashboardContent>
      {userCreateRouteConfig.validator.validate() === true && (
        <StyledCreateButton onClick={onCreateClick}>
          <FontAwesomeIcon icon={faPlus} />
        </StyledCreateButton>
      )}

      <HiddenLoader groups={"users_page_fetch"}>
        <StyledUsersList>
          {users?.map((user) => (
            <StyledUserCard key={user.id}>
              <StyledFlexItem>
                <StyledFlexRow columnOn="500px" columnAlign="start">
                  <StyledUserName>{user.name}</StyledUserName>

                  <StyledUserRole>
                    {LanguageHelper.translateRole(user.role)}
                  </StyledUserRole>
                </StyledFlexRow>
              </StyledFlexItem>

              <HiddenLoader groups={`users_page_delete_${user.id}`}>
                <StyledFlexRow style={{ gap: theme.spacing.small }}>
                  <StyledAltIconButton
                    onClick={() => onEditClick(user.id)}
                    disabled={userEditRouteConfig.validator.validate() !== true}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </StyledAltIconButton>

                  <StyledAltIconButton
                    onClick={() => onDeleteClick(user.id)}
                    disabled={deleteDisabled(user.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </StyledAltIconButton>
                </StyledFlexRow>
              </HiddenLoader>
            </StyledUserCard>
          ))}
        </StyledUsersList>
      </HiddenLoader>
    </StyledDashboardContent>
  );
}

export default UsersPageView;
