import PlacesPageFiltersView from "./PlacesPageFiltersView";

function PlacesPageFilters({ filtersController, placesData, setPlacesData }) {
  /**
   * This method will handle the close click
   */
  function onCloseClick() {
    if (placesData.showFilters === true) {
      setPlacesData((current) => ({
        ...current,
        showFilters: false,
      }));
    }
  }

  return (
    <PlacesPageFiltersView
      placesData={placesData}
      filtersController={filtersController}
      onCloseClick={onCloseClick}
    />
  );
}

export default PlacesPageFilters;
