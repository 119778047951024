import styled from "styled-components";
import { StyledCardHeader } from "../../styles/CardStyles";

export const Styledheader = styled(StyledCardHeader)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
`;
