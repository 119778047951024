import styled from "styled-components";
import { StyledAltIconButton } from "../../styles/InputStyles";

export const StyledPlaceInfoRequestsButton = styled.div`
  position: relative;
`;

export const StyledCountIndicator = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 15px;
  height: 15px;
  transform: translate(50%, -50%);
  background-color: ${(props) => props.theme.colors.accent.normal};
  color: ${(props) => props.theme.colors.on.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const StyledRequestsLayout = styled.div`
  position: relative;
  max-height: ${(props) => `calc(100vh - ${props.theme.spacing.large} * 4)`};
  max-width: ${(props) => props.theme.layout.maxWidth};
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.large};
  padding: ${(props) => props.theme.spacing.large};
`;

export const StyledRequest = styled.div`
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  background-color: ${(props) => props.theme.colors.primary.normal};
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  padding-bottom: ${(props) => props.theme.spacing.normal};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  margin-bottom: ${(props) => props.theme.spacing.normal};
`;

export const StyledPlaceName = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledUserName = styled.b`
  color: ${(props) => props.theme.colors.accent.normal};
`;

export const StyledContactDetail = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledActions = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledCloseButton = styled(StyledAltIconButton)`
  position: absolute;
  top: ${(props) => props.theme.spacing.normal};
  right: ${(props) => props.theme.spacing.normal};
`;
