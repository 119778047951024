import React, { useContext } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import DarkTheme from "../themes/DarkTheme";

const ThemeContext = React.createContext();

export function ThemeProvider({ children }) {
  const theme = DarkTheme;
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>;
}

export function useThemeContext() {
  return useContext(ThemeContext);
}
