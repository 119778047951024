import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledCard, StyledCardBody } from "../../styles/CardStyles";
import { Styledheader } from "./FoldableCardStyles";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

function FoldableCardView({ folded, setFolded, title, children }) {
  return (
    <StyledCard>
      <Styledheader onClick={() => setFolded((current) => !current)}>
        <h2>{title}</h2>

        <FontAwesomeIcon icon={folded ? faChevronUp : faChevronDown} />
      </Styledheader>

      {!folded && <StyledCardBody>{children}</StyledCardBody>}
    </StyledCard>
  );
}

export default FoldableCardView;
