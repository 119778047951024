import useAuthMiddleware from "../../../middlewares/AuthMiddleware";
import useMiddlewares from "../../../middlewares/Middleware";
import useRoleMiddleware from "../../../middlewares/RoleMiddleware";
import useRouteValidator from "../../RouteValidator";

function useSearchLogsRouteValidator() {
  return useRouteValidator(
    useMiddlewares([useAuthMiddleware(), useRoleMiddleware("admin")])
  );
}

export default useSearchLogsRouteValidator;
