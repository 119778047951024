import { RouteConfig } from "../../RouteConfig";
import { useNotFoundRouteValidator } from "../../validators/pages/NotFoundRouteValidator";

function useNotFoundRouteConfig() {
  return new RouteConfig()
    .setPath("*")
    .setValidator(useNotFoundRouteValidator());
}

export default useNotFoundRouteConfig;
