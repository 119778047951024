import styled from "styled-components";

export const StyledHiddenLoader = styled.div`
  position: relative;
`;

export const StyledChildrenWrapper = styled.div`
  opacity: ${(props) => (props.loading === "true" ? 0 : 1)};
  pointer-events: ${(props) => (props.loading === "true" ? "none" : "initial")};
`;

export const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
