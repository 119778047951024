import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledInput,
  StyledPlaceholder,
  StyledSelect,
  StyledValue,
} from "./SelectStyles";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

function SelectView({
  as: Element = StyledSelect,
  disabled,
  valueVisual,
  value,
  selectedOption,
  options,
  onChange,
  hideCaret,
  title,
}) {
  return (
    <Element disabled={disabled}>
      {valueVisual ? (
        // Custom value visual
        valueVisual(value)
      ) : selectedOption?.label ? (
        // Selected option
        <StyledValue>{selectedOption?.label}</StyledValue>
      ) : (
        // Placeholder
        <StyledPlaceholder>Selecteer</StyledPlaceholder>
      )}

      {/* Caret */}
      {!hideCaret && <FontAwesomeIcon icon={faCaretDown} size="sm" />}

      {/* Actual select */}
      <StyledInput
        value={!selectedOption ? "SELECT_PLACEHOLDER_VALUE" : `${value}`}
        onChange={onChange}
        disabled={disabled}
        title={title}
      >
        {/* options */}
        {options?.map((option) => (
          <option key={option.value} value={`${option.value}`}>
            {option.label}
          </option>
        ))}

        {/* placeholder value options */}
        <option
          style={{ display: "none" }}
          value="SELECT_PLACEHOLDER_VALUE"
        ></option>
      </StyledInput>
    </Element>
  );
}

export default SelectView;
