import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddressHelper } from "../../helpers/AddressHelper";
import {
  StyledActions,
  StyledCloseButton,
  StyledHeader,
  StyledHeaderTagsContainer,
  StyledInteractableTr,
  StyledName,
  StyledPlacePreview,
  StyledRequestInfoButton,
  StyledStatus,
  StyledTags,
  StyledTd,
  StyledType,
} from "./PlacePreviewStyles";
import {
  faBell,
  faCalendarPlus,
  faEye,
  faLocationDot,
  faPencil,
  faPhone,
  faPlus,
  faTrash,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { StyledAltIconButton } from "../../styles/InputStyles";
import HiddenLoader from "../loader/hidden/HiddenLoader";
import LanguageHelper from "../../helpers/LanguageHelper";
import { useAuthContext } from "../../contexts/AuthContext";

function PlacePreviewView({
  requested,
  place,
  enableClose,
  onCloseClick,
  onAddClick,
  onEditClick,
  onDeleteClick,
  onPhoneClick,
  onLocationClick,
  onRequestInfoClick,
}) {
  const authContext = useAuthContext();
  const userName = place.user?.name ?? place.user_name;
  const readonly = authContext.auth?.user?.role !== "admin";

  return (
    <StyledPlacePreview>
      <StyledHeader>
        <StyledHeaderTagsContainer>
          {/* Name */}
          <StyledName>{place.name}</StyledName>

          {/* Type and status */}
          <StyledTags>
            <StyledType>
              {LanguageHelper.translatePlaceType(place.type)}
            </StyledType>

            <StyledStatus status={place.status}>
              {LanguageHelper.translatePlaceStatus(place.status)}
            </StyledStatus>
          </StyledTags>
        </StyledHeaderTagsContainer>

        <HiddenLoader groups={`place_preview_delete_${place.id}`}>
          <StyledActions>
            {/* Edit or Add button */}
            {(place.status !== "unknown" || !readonly) && (
              <StyledAltIconButton
                onClick={place.status === "unknown" ? onAddClick : onEditClick}
              >
                <FontAwesomeIcon
                  icon={
                    readonly
                      ? faEye
                      : place.status === "unknown"
                      ? faPlus
                      : faPencil
                  }
                />
              </StyledAltIconButton>
            )}

            {/* Delete button */}
            {place.status !== "unknown" &&
              authContext.auth?.user?.role === "admin" && (
                <StyledAltIconButton onClick={onDeleteClick}>
                  <FontAwesomeIcon icon={faTrash} />
                </StyledAltIconButton>
              )}

            {/* Request info button */}
            {place.user && place.user.id !== authContext.auth?.user?.id && (
              <HiddenLoader groups={`place_info_requests_fetch`}>
                <StyledRequestInfoButton
                  requested={requested}
                  onClick={onRequestInfoClick}
                >
                  <FontAwesomeIcon icon={faBell} />
                </StyledRequestInfoButton>
              </HiddenLoader>
            )}
          </StyledActions>
        </HiddenLoader>
      </StyledHeader>

      <table>
        <tbody>
          {/* Address */}
          <StyledInteractableTr onClick={onLocationClick}>
            <StyledTd>
              <FontAwesomeIcon icon={faLocationDot} />
            </StyledTd>
            <StyledTd>
              {AddressHelper.getFullAddress(place, ["country"])}
            </StyledTd>
          </StyledInteractableTr>

          {/* Phone number */}
          {place.phone_number && (
            <StyledInteractableTr onClick={onPhoneClick}>
              <StyledTd>
                <FontAwesomeIcon icon={faPhone} />
              </StyledTd>
              <StyledTd>{place.phone_number}</StyledTd>
            </StyledInteractableTr>
          )}

          {/* User */}
          {userName && (
            <tr>
              <StyledTd>
                <FontAwesomeIcon icon={faUser} />
              </StyledTd>
              <StyledTd>{userName}</StyledTd>
            </tr>
          )}

          {/* Created at */}
          {place.created_at && (
            <tr>
              <StyledTd>
                <FontAwesomeIcon icon={faCalendarPlus} />
              </StyledTd>
              <StyledTd>
                {new Date(place.created_at).toLocaleDateString()}
              </StyledTd>
            </tr>
          )}
        </tbody>
      </table>

      {enableClose && (
        <StyledCloseButton onClick={onCloseClick}>
          <FontAwesomeIcon icon={faXmark} />
        </StyledCloseButton>
      )}
    </StyledPlacePreview>
  );
}

export default PlacePreviewView;
