import GoogleMap from "../../../../components/googleMaps/GoogleMap";
import GoogleMapCircle from "../../../../components/googleMaps/GoogleMapCircle";
import GoogleMapMarker from "../../../../components/googleMaps/GoogleMapMarker";
import { StyledMapInfo } from "./PlacesPageMapStyles";
import PlaceHelper from "../../../../helpers/PlaceHelper";

function PlacesPageMapView({
  googleMaps,
  placesData,
  filtersController,
  googleMapsPositionData,
  onPlaceMarkerClick,
}) {
  return (
    <>
      <GoogleMap
        controller={googleMaps}
        position={googleMapsPositionData.position}
        zoom={googleMapsPositionData.zoom}
      >
        {placesData.places.map((place) => (
          <GoogleMapMarker
            key={place.location.latitude + place.location.longitude}
            controller={googleMaps}
            position={googleMaps.getGeoLocation(place.location)}
            title={place.name}
            color={PlaceHelper.getStatusColor(place.status).background}
            onClick={() => onPlaceMarkerClick(place)}
          />
        ))}

        {placesData.location && (
          <>
            <GoogleMapCircle
              controller={googleMaps}
              center={googleMaps.getGeoLocation(placesData.location)}
              radius={filtersController.filters.search_radius}
            />

            <GoogleMapCircle
              controller={googleMaps}
              center={googleMaps.getGeoLocation(placesData.location)}
              radius={100}
            />
          </>
        )}
      </GoogleMap>

      {placesData.google_places_count && (
        <StyledMapInfo>
          <b>In zoekgebied: {placesData.google_places_count}</b>
        </StyledMapInfo>
      )}
    </>
  );
}

export default PlacesPageMapView;
