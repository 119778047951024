import styled from "styled-components";

export const StyledTableWrapper = styled.div`
  overflow-x: auto;
  border-radius: ${(props) => props.theme.radius.normal};
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: ${(props) => props.theme.colors.on.primary};
`;

export const StyledTr = styled.tr`
  thead & {
    background-color: ${(props) => props.theme.colors.primary.darkest};
  }

  tbody & {
    &:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.primary.normal};
    }
    &:nth-child(even) {
      background-color: ${(props) => props.theme.colors.primary.light};
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.primary.darkest};
    }
  }
`;

export const StyledTh = styled.th`
  text-align: left;
  border-left: 1px solid ${(props) => props.theme.colors.primary.darkest};
  border-right: 1px solid ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};

  &:last-child {
    border-right-width: 0px;
  }
  &:first-child {
    border-left-width: 0px;
  }
`;

export const StyledTd = styled.td`
  text-align: left;
  border-left: 1px solid ${(props) => props.theme.colors.primary.darkest};
  border-right: 1px solid ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};

  &:last-child {
    border-right-width: 0px;
  }
  &:first-child {
    border-left-width: 0px;
  }
`;
