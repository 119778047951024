import { RouteConfig } from "../../../RouteConfig";
import PlacesPage from "../../../../pages/places/index/PlacesPage";
import usePlacesRouteValidator from "../../../validators/pages/places/PlacesRouteValidator";

function usePlacesRouteConfig() {
  return new RouteConfig()
    .setPath("/dashboard/places")
    .setLabel("Plaatsen")
    .setValidator(usePlacesRouteValidator())
    .setPage(<PlacesPage />);
}

export default usePlacesRouteConfig;
