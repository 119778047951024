export class ApiHelper {
  /**
   * This method will apply the errors in the response to the given errors object
   * @param {object} response
   * @returns {object}
   */
  static getResponseErrors(response) {
    const errors = {};

    //Add the response errors to the errors object
    const responseErrors = response?.response?.data?.errors;
    if (responseErrors) {
      Object.keys(responseErrors).forEach((key) => {
        errors[key] = responseErrors[key][0];
      });
    }

    //Add the message to the errors object if there are no other errors
    if (response?.response?.data?.message && Object.keys(errors).length === 0) {
      errors["message"] = response.response.data.message;
    }

    return errors;
  }

  /**
   * This method will convert the given object to a FormData object
   * @param {object} data
   * @param {boolean} put
   * @param {string[]} $ignore
   * @returns
   */
  static objectToFormData(data, put = false, $ignore = ["fetched"]) {
    const formData = new FormData();
    for (const key in data) {
      const value = data[key];
      //Skip these key value pairs
      if ($ignore.includes(key)) {
        continue;
      } else if (
        (key === "image" || key === "file") &&
        typeof value === "string"
      ) {
        continue;
      }

      //Add the key and value to the formData
      if (key === "schedule") {
        this.appendSchedulingToRequestData(value, formData);
      } else if (Array.isArray(value)) {
        value.forEach((arrayValue, index) => {
          if (typeof arrayValue === "object") {
            for (const arrayValueKey in arrayValue) {
              const arrayValueValue = arrayValue[arrayValueKey];
              if (arrayValueValue !== null && arrayValueValue !== undefined) {
                formData.append(
                  `${key}[${index}][${arrayValueKey}]`,
                  arrayValueValue
                );
              }
            }
          } else {
            formData.append(`${key}[${index}]`, arrayValue);
          }
        });
      } else if (typeof value === "boolean") {
        formData.append(key, value ? 1 : 0);
      } else if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }

    if (put) {
      formData.append("_method", "PUT");
    }

    return formData;
  }
}
