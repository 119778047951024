import styled from "styled-components";

export const StyledDashboard = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledHeaderLayout = styled.div`
  margin-inline: auto;
  max-width: ${(props) => props.theme.layout.maxWidth};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.normal};
  padding-inline: ${(props) => props.theme.spacing.large};
`;

export const StyledUserName = styled.p`
  font-weight: bold;
  margin-left: auto;

  @media (max-width: 525px) {
    display: none;
  }
`;

export const StyledBody = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const StyledDashboardContent = styled.div`
  margin-inline: auto;
  max-width: ${(props) => props.theme.layout.maxWidth};
  padding-inline: ${(props) => props.theme.spacing.large};
  padding-top: ${(props) => props.theme.spacing.large};
  padding-bottom: ${(props) => props.theme.spacing.large};
`;
