import styled from "styled-components";

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRequired = styled.span`
  color: ${(props) => props.theme.colors.accent.normal};
`;
