import { useAuthContext } from "../../../contexts/AuthContext";
import useRouteValidator from "../../RouteValidator";

export function useNotFoundRouteValidator() {
  const authContext = useAuthContext();

  return useRouteValidator(() => {
    if (!authContext.auth.token) {
      return { to: "/login", replace: true };
    } else {
      return { to: "/dashboard/places", replace: true };
    }
  });
}
