import styled from "styled-components";
import {
  StyledAltButton,
  StyledTextButton,
} from "../../../../styles/InputStyles";

export const StyledMultiSelect = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  width: 100%;
`;

export const StyledValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledValue = styled(StyledAltButton)`
  background-color: ${(props) => props.theme.colors.primary.light};
  border-radius: ${(props) => props.theme.radius.small};
  padding-block: ${(props) => props.theme.spacing.small};
  font-size: ${(props) => props.theme.font.size.sm};
  z-index: 1;
  opacity: 1;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledAction = styled(StyledTextButton)`
  color: ${(props) => props.theme.colors.on.primary};
  opacity: 1;
  z-index: 1;
`;

export const StyledPlaceholder = styled.p`
  color: ${(props) => props.theme.colors.on.primary};
  opacity: 0.3;
`;
