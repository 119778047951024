import { Navigate, Route, useParams } from "react-router-dom";
import { useRouteContext } from "../contexts/RouteContext";
import useCustomEffect from "../hooks/useCustomEffect";
import { useRef } from "react";

export class RouteConfig {
  constructor() {
    this.path = "";
    this.label = "";
    this.validator = null;
    this.page = null;
    this.parent = null;
    this.previousStep = null;
    this.pathSteps = [];
  }

  setPath(path) {
    this.path = path;
    return this;
  }

  setLabel(label) {
    this.label = label;
    return this;
  }

  setValidator(validator) {
    this.validator = validator;
    return this;
  }

  setPage(page) {
    this.page = page;
    return this;
  }

  setParent(parent) {
    this.parent = parent;
    return this;
  }

  getRoute() {
    return (
      <Route
        path={this.path}
        element={<RouteConfigElement routeConfig={this} />}
      />
    );
  }

  setPreviousStep(previousStep) {
    this.previousStep = previousStep;
    return this;
  }

  setPathSteps(pathSteps) {
    this.pathSteps = pathSteps;
    return this;
  }
}

export function RouteConfigElement({ routeConfig }) {
  const routeContext = useRouteContext();
  const params = useParams();
  const validation = routeConfig.validator.validate();
  const initializedRef = useRef(false);

  useCustomEffect(() => {
    routeConfig.previousStep = getPreviousStep(routeConfig);
    routeConfig.pathSteps = getPathSteps(routeConfig);
    routeContext.updateRouteConfig(routeConfig);
    initializedRef.current = true;
  }, [params, routeConfig]);

  /**
   * This method will return the path params from the path.
   * @param {{ path, label, validator, page, parent, getRoute }} config
   * @returns {string[]}
   */
  function getPathParamNames(config) {
    const pathParts = config.path.split("/");
    const paramNames = pathParts
      .filter((part) => part.startsWith(":"))
      .map((part) => part.replace(":", ""));
    return paramNames;
  }

  /**
   * This method will return the path based on the current url
   * @param {{ path, label, validator, page, parent, getRoute }} config
   * @returns {string}
   */
  function getPathWithParams(config) {
    const paramNames = getPathParamNames(config);
    let pathFromUrl = config.path.slice();
    paramNames.forEach((param) => {
      pathFromUrl = pathFromUrl.replace(`:${param}`, params[param]);
    });
    return pathFromUrl;
  }

  /**
   * This method will return the path steps from the root route to the current route.
   * @param {{ path, label, validator, page, parent, getRoute }} config
   * @param {boolean} reverse If true, the path list will be reversed.
   * @returns {{ path, label }[]}
   */
  function getPathSteps(config, reverse = true) {
    let steps = [
      {
        path: getPathWithParams(config),
        label: config.label,
      },
    ];
    if (config.parent) {
      steps = [...steps, ...getPathSteps(config.parent, false)];
    }
    return reverse ? steps.reverse() : steps;
  }

  /**
   * This method will return the step of the parent route.
   * @param {{ path, label, validator, page, parent, getRoute }} config
   * @returns {{ path, label, validator, page, parent, getRoute, pathWithParams }}
   */
  function getPreviousStep(config) {
    return config.parent
      ? {
          path: getPathWithParams(config.parent),
          label: config.parent.label,
        }
      : null;
  }

  if (validation === true) {
    return initializedRef.current ? routeConfig.page : "";
  } else {
    return <Navigate to={validation.to} replace={validation.replace} />;
  }
}
