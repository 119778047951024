import InteractiveInputView from "./InteractiveInputView";

function InteractiveInput({
  value,
  type,
  placeholder,
  disabled,
  onChange,
  onConfirm,
  style,
}) {
  function onClear() {
    if (onChange) {
      onChange({ target: { value: "" }, clearEvent: true });
    }
  }

  function onKeyUp(event) {
    if (event.key === "Enter" && onConfirm) {
      onConfirm();
    }
  }

  return (
    <InteractiveInputView
      value={value}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      onClear={onClear}
      onKeyUp={onKeyUp}
      style={style}
    />
  );
}

export default InteractiveInput;
