import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledActionsWrapper,
  StyledSearchBar,
  StyledSearchBarLayout,
} from "./PlacesPageSearchBarStyles";
import {
  faList,
  faLocationDot,
  faMagnifyingGlass,
  faPlus,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import {
  StyledAltIconButton,
  StyledIconButton,
} from "../../../../styles/InputStyles";
import { useAuthContext } from "../../../../contexts/AuthContext";
import InteractiveInput from "../../../../components/interactiveInput/InteractiveInput";

function PlacesPageSearchBarView({
  placesData,
  filtersController,
  onShowMapChange,
  onFiltersClick,
  onConfirmSearch,
  onAddClick,
}) {
  const authContext = useAuthContext();
  const readonly = authContext.auth?.user?.role !== "admin";

  return (
    <StyledSearchBar>
      <StyledSearchBarLayout>
        <InteractiveInput
          value={filtersController.filters.text_search}
          onChange={filtersController.onTextSearchChange}
          onConfirm={onConfirmSearch}
          placeholder="Zoeken..."
          style={{ flex: 1 }}
        />

        <StyledActionsWrapper>
          <StyledIconButton onClick={onConfirmSearch}>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </StyledIconButton>

          {!readonly && (
            <StyledIconButton onClick={onAddClick}>
              <FontAwesomeIcon icon={faPlus} />
            </StyledIconButton>
          )}

          <StyledAltIconButton onClick={onFiltersClick}>
            <FontAwesomeIcon icon={faSliders} />
          </StyledAltIconButton>

          <StyledAltIconButton
            onClick={() => onShowMapChange(!placesData.showMap)}
          >
            <FontAwesomeIcon
              icon={placesData.showMap ? faList : faLocationDot}
            />
          </StyledAltIconButton>
        </StyledActionsWrapper>
      </StyledSearchBarLayout>
    </StyledSearchBar>
  );
}

export default PlacesPageSearchBarView;
