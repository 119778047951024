import styled from "styled-components";
import { StyledAltIconButton } from "../../styles/InputStyles";

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.66);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: ${(props) => props.theme.spacing.large};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledPreviewContainer = styled.div`
  position: relative;
`;

export const StyledPreview = styled.img`
  max-width: ${(props) => props.theme.layout.maxWidth};
  max-height: 100%;
  object-fit: cover;

  @media (max-width: ${(props) => props.theme.layout.maxWidth}) {
    max-width: 100%;
  }
`;

export const StyledPdfPreview = styled.iframe`
  width: ${(props) => `calc(100vw - ${props.theme.spacing.large} * 2)`};
  height: ${(props) => `calc(100vh - ${props.theme.spacing.large} * 2)`};
`;

export const StyledCloseButton = styled(StyledAltIconButton)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;
