import { useState } from "react";
import LoginPageView from "./LoginPageView";
import { useAuthContext } from "../../contexts/AuthContext";
import { ApiHelper } from "../../helpers/ApiHelper";

function LoginPage() {
  const authContext = useAuthContext();
  const [tfaQRCodeUrl, setTfaQRCodeUrl] = useState(null);
  const [errors, setErrors] = useState(null);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    tfa_code: "",
  });

  /**
   * Handle the email change event
   * @param {event} e
   */
  function onEmailChange(e) {
    setLoginData((current) => ({ ...current, email: e.target.value }));
  }

  /**
   * Handle the password change event
   * @param {event} e
   */
  function onPasswordChange(e) {
    setLoginData((current) => ({ ...current, password: e.target.value }));
  }

  /**
   * This method will update the tfa_code on input change
   * @param {event} e
   */
  function onTfaCodeChange(e) {
    setLoginData({ ...loginData, tfa_code: e.target.value });
  }

  /**
   * This method will copy the QR code to the clipboard
   */
  function onQrCodeClick() {
    navigator.clipboard.writeText(
      new URL(tfaQRCodeUrl).searchParams.get("secret")
    );
    alert("QR code gekopieerd naar klembord");
  }

  /**
   * This method will try to login the user
   */
  function onSubmit() {
    setErrors(null);

    authContext.login(loginData, null, (error) => {
      setTfaQRCodeUrl(error.response?.data?.data?.tfa_qr_code_url);
      setErrors(ApiHelper.getResponseErrors(error));
    });
  }

  return (
    <LoginPageView
      loginData={loginData}
      tfaQRCodeUrl={tfaQRCodeUrl}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onTfaCodeChange={onTfaCodeChange}
      onQrCodeClick={onQrCodeClick}
      onSubmit={onSubmit}
      errors={errors}
    />
  );
}

export default LoginPage;
