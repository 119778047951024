import React, { useContext, useState } from "react";

const RouteContext = React.createContext();

export function RouteProvider({ children }) {
  const [routeConfig, setCurrentRouteConfig] = useState(null);

  function updateRouteConfig(newRouteConfig) {
    setCurrentRouteConfig(newRouteConfig);
  }

  return (
    <RouteContext.Provider value={{ routeConfig, updateRouteConfig }}>
      {children}
    </RouteContext.Provider>
  );
}

export function useRouteContext() {
  return useContext(RouteContext);
}
