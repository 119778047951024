import { useState } from "react";
import UserPageView from "./UserPageView";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadContext } from "../../../contexts/LoadContext";
import { ApiHelper } from "../../../helpers/ApiHelper";
import api from "../../../apis/api";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../contexts/RouteContext";

function UserPage() {
  const routeContext = useRouteContext();
  const loadContext = useLoadContext();
  const navigate = useNavigate();
  const { user } = useParams();
  const [errors, setErrors] = useState(null);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
    role: "user",
  });

  // Initialize the component
  useCustomEffect(() => {
    if (user) {
      fetchUser();
    }
  });

  /**
   * This method will fetch the user from the API
   */
  async function fetchUser() {
    const loadTag = loadContext.enableLoader("user_page_fetch");

    try {
      const response = await api.get(`users/${user}`);
      if (response.status === 200 && response.data?.success) {
        setUserData({
          name: response.data.data.name,
          email: response.data.data.email,
          role: response.data.data.role,
          phone_number: response.data.data.phone_number,
        });
      }
    } catch (error) {
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will handle the name change
   * @param {event} e
   */
  function onNameChange(e) {
    setUserData((current) => ({ ...current, name: e.target.value }));
  }

  /**
   * This method will handle the email change
   * @param {event} e
   */
  function onEmailChange(e) {
    setUserData((current) => ({ ...current, email: e.target.value }));
  }

  /**
   * This method will handle the password change
   * @param {event} e
   */
  function onPasswordChange(e) {
    setUserData((current) => ({ ...current, password: e.target.value }));
  }

  /**
   * This method will handle the role change
   * @param {any} value
   */
  function onRoleChange(value) {
    setUserData((current) => ({ ...current, role: value }));
  }

  /**
   * This method will handle the phone number change
   * @param {Event} e
   */
  function onPhoneNumberChange(e) {
    setUserData((current) => ({ ...current, phone_number: e.target.value }));
  }

  /**
   * This method will handle the cancel
   */
  function onCancel() {
    navigate(routeContext.routeConfig.previousStep.path);
  }

  /**
   * This method will handle the submit
   */
  async function onSubmit() {
    const loadTag = loadContext.enableLoader("user_page_submit");

    setErrors(null);

    try {
      const response = await api.post(
        user ? `users/${user}` : "users",
        ApiHelper.objectToFormData(userData, user)
      );
      if (response.status === 200 && response.data?.success) {
        navigate(routeContext.routeConfig.previousStep.path);
      }
    } catch (error) {
      setErrors(ApiHelper.getResponseErrors(error));
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  return (
    <UserPageView
      user={user}
      userData={userData}
      onNameChange={onNameChange}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onRoleChange={onRoleChange}
      onPhoneNumberChange={onPhoneNumberChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      errors={errors}
    />
  );
}

export default UserPage;
