const DarkTheme = {
  colors: {
    primary: {
      darkest: "#111827",
      dark: "#1f2937",
      normal: "#374151",
      light: "#3e4858",
      lightest: "#4b5563",
      lightestRGB: "75, 85, 99",
    },

    accent: {
      dark: "#10b981",
      normal: "#34d399",
      normalRGB: "52, 211, 153",
    },

    error: "#34d399",

    on: {
      primary: "white",
      accent: "white",
      error: "white",
    },
  },

  spacing: {
    small: "3.5px",
    normal: "7.5px",
    large: "20px",
  },

  font: {
    size: {
      small: "10.5px",
      normal: "13.5px",
      large: "20.25px",
      xl: "27px",
    },
  },

  radius: {
    small: "6.75px",
    normal: "10px",
  },

  layout: {
    maxWidth: "1028px",
  },
};

export default DarkTheme;
