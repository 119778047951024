import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledScreenOverlay } from "./ScreenLoaderStyles";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function ScreenLoaderView() {
  return (
    <StyledScreenOverlay>
      <FontAwesomeIcon icon={faSpinner} spin size="2xl" />
    </StyledScreenOverlay>
  );
}

export default ScreenLoaderView;
