import React, { useContext, useRef, useState } from "react";
import { useLoadContext } from "./LoadContext";
import useCustomEffect from "../hooks/useCustomEffect";
import api from "../apis/api";
import { useAuthContext } from "./AuthContext";
import { clear } from "@testing-library/user-event/dist/clear";

const PlaceInfoRequestContext = React.createContext();

export function PlaceInfoRequestProvider({ children }) {
  const authContext = useAuthContext();
  const loadContext = useLoadContext();
  const timeoutRef = useRef(null);
  const [data, setData] = useState({
    requests: [],
    requested: [],
  });

  /**
   * UseEffect for auto fetching the place info requests
   */
  useCustomEffect(() => {
    fetchPlaceInfoRequests();
  }, [authContext.auth.token]);

  /**
   * This method will fetch the place info requests from the API
   */
  async function fetchPlaceInfoRequests() {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(fetchPlaceInfoRequests, 300000);

    //if the user is not logged in, clear the data and return
    if (!authContext.auth.token) {
      setData({
        requests: [],
        requested: [],
      });
      return;
    }

    //enable the loader
    const loadTag = loadContext.enableLoader("place_info_requests_fetch");

    try {
      //fetch the place info requests
      const response = await api.get("place_info_requests", {
        params: {
          with_place_info_request_user_data: true,
          with_place_info_request_place_data: true,
        },
      });
      if (response.status === 200 && response.data?.success) {
        setData({
          requests: response.data.data.requests,
          requested: response.data.data.requested,
        });
      }
    } catch (error) {
      setData({
        requests: [],
        requested: [],
      });
    } finally {
      //disable the loader
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will delete the place info request with the given id
   * @param {int} id
   * @param {boolean} warn
   */
  async function deletePlaceInfoRequest(id, warn = true) {
    if (
      !authContext.auth.token ||
      (warn &&
        !window.confirm("Weet je zeker dat je deze melding wilt verwijderen?"))
    ) {
      return;
    }

    //enable the loader
    const loadTag = loadContext.enableLoader(
      `place_info_requests_delete_${id}`
    );

    try {
      //delete the place info request
      await api.delete(`place_info_requests/${id}`);
    } catch (error) {
    } finally {
      //disable the loader and fetch the place info requests
      fetchPlaceInfoRequests();
      loadContext.disableLoader(loadTag);
    }
  }

  return (
    <PlaceInfoRequestContext.Provider
      value={{
        data,
        fetchPlaceInfoRequests,
        deletePlaceInfoRequest,
      }}
    >
      {children}
    </PlaceInfoRequestContext.Provider>
  );
}

export function usePlaceInfoRequestContext() {
  return useContext(PlaceInfoRequestContext);
}
