import { useTheme } from "styled-components";
import OverlayLoader from "../../../components/loader/overlay/OverlayLoader";
import { StyledDashboardContent } from "../../../layouts/dashboard/DashboardLayoutStyles";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../../styles/CardStyles";
import { StyledFlexColumn, StyledFlexRow } from "../../../styles/LayoutStyles";
import { StyledAltButton } from "../../../styles/InputStyles";
import HiddenLoader from "../../../components/loader/hidden/HiddenLoader";
import { StyledError } from "../../../styles/ErrorStyles";
import InputWrapper from "../../../components/inputWrapper/InputWrapper";
import Select from "../../../components/select/main/Select";
import LanguageHelper from "../../../helpers/LanguageHelper";
import PlacePageFiles from "./files/PlacePageFiles";
import FoldableCard from "../../../components/foldableCard/FoldableCard";
import PlaceHelper from "../../../helpers/PlaceHelper";
import { useAuthContext } from "../../../contexts/AuthContext";
import InteractiveInput from "../../../components/interactiveInput/InteractiveInput";

function PlacePageView({
  place,
  placeData,
  onNameChange,
  onTypeChange,
  onStatusChange,
  onStateChange,
  onCityChange,
  onAddressChange,
  onPostalCodeChange,
  onPhoneNumberChange,
  onAvailableRoomCountChange,
  onAvailablePersonCountChange,
  onBookedRoomCountChange,
  onBookedPersonCountChange,
  onContactNameChange,
  onContactPositionChange,
  onContactEmailChange,
  onContactPhoneChange,
  onAvailableFromChange,
  onAvailableUntilChange,
  onDetailsChange,
  onCancel,
  onSubmit,
  errors,
}) {
  const theme = useTheme();
  const authContext = useAuthContext();
  const readonly = authContext.auth.user?.role !== "admin";

  return (
    <StyledDashboardContent>
      <StyledFlexColumn style={{ gap: theme.spacing.large }}>
        <OverlayLoader groups={"place_page_fetch"} rounded={true}>
          <StyledCard>
            <StyledCardHeader>
              <h2>{`Plaats ${
                readonly ? "Bekijken" : place ? "Bewerken" : "Aanmaken"
              }`}</h2>
            </StyledCardHeader>

            <StyledCardBody>
              <StyledFlexColumn>
                {/* Name */}
                <InputWrapper label="Naam" required error={errors?.name}>
                  <input
                    disabled={readonly}
                    type="text"
                    value={placeData.name}
                    onChange={onNameChange}
                    placeholder="Naam"
                  />
                </InputWrapper>

                {/* Type */}
                <InputWrapper label="Type" required error={errors?.type}>
                  <Select
                    disabled={readonly}
                    value={placeData.type}
                    options={PlaceHelper.getTypes().map((type) => ({
                      value: type,
                      label: LanguageHelper.translatePlaceType(type),
                    }))}
                    onChange={onTypeChange}
                  />
                </InputWrapper>

                {/* Status */}
                <InputWrapper label="Status" required error={errors?.status}>
                  <Select
                    disabled={readonly}
                    value={placeData.status}
                    options={PlaceHelper.getStatuses().map((status) => ({
                      value: status,
                      label: LanguageHelper.translatePlaceStatus(status),
                    }))}
                    onChange={onStatusChange}
                  />
                </InputWrapper>

                {/* Phone number */}
                <InputWrapper
                  label="Telefoonnummer"
                  error={errors?.phone_number}
                >
                  <input
                    disabled={readonly}
                    type="tel"
                    value={placeData.phone_number}
                    onChange={onPhoneNumberChange}
                    placeholder="+31 6 12345678"
                  />
                </InputWrapper>

                {/* Details */}
                <InputWrapper label="Details" error={errors?.details}>
                  <textarea
                    disabled={readonly}
                    value={placeData.details}
                    onChange={onDetailsChange}
                    placeholder="Details"
                  />
                </InputWrapper>
              </StyledFlexColumn>

              <StyledFlexColumn style={{ marginTop: theme.spacing.large }}>
                <StyledFlexRow>
                  <StyledAltButton onClick={onCancel}>Annuleer</StyledAltButton>

                  {authContext.auth.user?.role === "admin" && (
                    <HiddenLoader groups={"place_page_submit"}>
                      <button onClick={onSubmit}>Opslaan</button>
                    </HiddenLoader>
                  )}
                </StyledFlexRow>

                {errors?.message && (
                  <StyledError>{errors?.message}</StyledError>
                )}
              </StyledFlexColumn>
            </StyledCardBody>
          </StyledCard>
        </OverlayLoader>

        <OverlayLoader groups={"place_page_fetch"} rounded={true}>
          <FoldableCard title="Adres">
            <StyledFlexColumn>
              {/* State */}
              <InputWrapper label="Provincie" error={errors?.state} required>
                <input
                  disabled={readonly}
                  type="text"
                  value={placeData.state}
                  onChange={onStateChange}
                  placeholder="Gelderland"
                />
              </InputWrapper>

              {/* City */}
              <InputWrapper label="Stad" error={errors?.city} required>
                <input
                  disabled={readonly}
                  type="text"
                  value={placeData.city}
                  onChange={onCityChange}
                  placeholder="Arnhem"
                />
              </InputWrapper>

              {/* Address */}
              <InputWrapper label="Adres" error={errors?.address} required>
                <input
                  disabled={readonly}
                  type="text"
                  value={placeData.address}
                  onChange={onAddressChange}
                  placeholder="Straatnaam 123"
                />
              </InputWrapper>

              {/* Postal code */}
              <InputWrapper
                label="Postcode"
                error={errors?.postal_code}
                required
              >
                <input
                  disabled={readonly}
                  type="text"
                  value={placeData.postal_code}
                  onChange={onPostalCodeChange}
                  placeholder="1234 AB"
                />
              </InputWrapper>
            </StyledFlexColumn>
          </FoldableCard>
        </OverlayLoader>

        <OverlayLoader groups={"place_page_fetch"} rounded={true}>
          <FoldableCard title="Contact">
            <StyledFlexColumn>
              {/* Contact name */}
              <InputWrapper label="Naam" error={errors?.contact_name}>
                <input
                  disabled={readonly}
                  type="text"
                  value={placeData.contact_name}
                  onChange={onContactNameChange}
                  placeholder="Naam"
                />
              </InputWrapper>

              {/* Contact position */}
              <InputWrapper label="Functie" error={errors?.contact_position}>
                <input
                  disabled={readonly}
                  type="text"
                  value={placeData.contact_position}
                  onChange={onContactPositionChange}
                  placeholder="Secretaresse"
                />
              </InputWrapper>

              {/* Contact email */}
              <InputWrapper label="Email" error={errors?.contact_email}>
                <input
                  disabled={readonly}
                  type="email"
                  value={placeData.contact_email}
                  onChange={onContactEmailChange}
                  placeholder="voorbeeld@email.com"
                />
              </InputWrapper>

              {/* Contact phone */}
              <InputWrapper
                label="Telefoonnummer"
                error={errors?.contact_phone}
              >
                <input
                  disabled={readonly}
                  type="tel"
                  value={placeData.contact_phone}
                  onChange={onContactPhoneChange}
                  placeholder="+31 6 12345678"
                />
              </InputWrapper>
            </StyledFlexColumn>
          </FoldableCard>
        </OverlayLoader>

        <OverlayLoader groups={"place_page_fetch"} rounded={true}>
          <FoldableCard title="Beschikbaarheid">
            <StyledFlexColumn>
              {/* Available room count */}
              <InputWrapper
                required
                label="Kamers beschikbaar"
                error={errors?.available_room_count}
              >
                <input
                  disabled={readonly}
                  type="number"
                  value={placeData.available_room_count}
                  onChange={onAvailableRoomCountChange}
                  placeholder="0"
                />
              </InputWrapper>

              {/* Booked room count */}
              <InputWrapper
                required
                label="Kamers gereserveerd"
                error={errors?.booked_room_count}
              >
                <input
                  disabled={readonly}
                  type="number"
                  value={placeData.booked_room_count}
                  onChange={onBookedRoomCountChange}
                  placeholder="0"
                />
              </InputWrapper>

              {/* Available person count */}
              <InputWrapper
                required
                label="Personen beschikbaar"
                error={errors?.available_person_count}
              >
                <input
                  disabled={readonly}
                  type="number"
                  value={placeData.available_person_count}
                  onChange={onAvailablePersonCountChange}
                  placeholder="0"
                />
              </InputWrapper>

              {/* Booked person count */}
              <InputWrapper
                required
                label="Personen gereserveerd"
                error={errors?.booked_person_count}
              >
                <input
                  disabled={readonly}
                  type="number"
                  value={placeData.booked_person_count}
                  onChange={onBookedPersonCountChange}
                  placeholder="0"
                />
              </InputWrapper>

              {/* Available vanaf */}
              <InputWrapper
                label="Beschikbaar vanaf"
                error={errors?.available_from}
              >
                <InteractiveInput
                  value={placeData.available_from}
                  type={"date"}
                  onChange={onAvailableFromChange}
                  disabled={readonly}
                />
              </InputWrapper>

              {/* Available tot */}
              <InputWrapper
                label="Beschikbaar tot"
                error={errors?.available_until}
              >
                <InteractiveInput
                  value={placeData.available_until}
                  type={"date"}
                  onChange={onAvailableUntilChange}
                  disabled={readonly}
                />
              </InputWrapper>
            </StyledFlexColumn>
          </FoldableCard>
        </OverlayLoader>
      </StyledFlexColumn>

      {place && <PlacePageFiles placeId={place} />}
    </StyledDashboardContent>
  );
}

export default PlacePageView;
