import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  StyledClearButton,
  StyledInput,
  StyledInteractiveInput,
} from "./InteractiveInputStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InteractiveInputView({
  value,
  type,
  placeholder,
  disabled,
  onChange,
  onClear,
  onKeyUp,
  style,
}) {
  return (
    <StyledInteractiveInput style={style} disabled={disabled}>
      <StyledInput
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />

      {value?.length > 0 && (
        <StyledClearButton onClick={onClear}>
          <FontAwesomeIcon icon={faXmark} />
        </StyledClearButton>
      )}
    </StyledInteractiveInput>
  );
}

export default InteractiveInputView;
