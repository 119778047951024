import UsersPage from "../../../../pages/users/UsersPage";
import useUsersRouteValidator from "../../../validators/pages/users/UsersRouteValidator";
import { RouteConfig } from "../../../RouteConfig";

function useUsersRouteConfig() {
  return new RouteConfig()
    .setPath("/dashboard/users")
    .setLabel("Gebruikers")
    .setValidator(useUsersRouteValidator())
    .setPage(<UsersPage />);
}

export default useUsersRouteConfig;
