import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import GlobalStyles from "./styles/global/GlobalStyles";
import { LoadProvider } from "./contexts/LoadContext";
import { AuthProvider } from "./contexts/AuthContext";
import { ContextHandler } from "./contexts/HandlerContext";
import Routes from "./routes/Routes";
import { GoogleMapsProvider } from "./contexts/GoogleMapsContext";
import { RouteProvider } from "./contexts/RouteContext";
import { PlaceInfoRequestProvider } from "./contexts/PlaceInfoRequestContext";

function App() {
  return (
    <ThemeProvider>
      <GlobalStyles />

      <BrowserRouter>
        <LoadProvider>
          <AuthProvider>
            <GoogleMapsProvider>
              <RouteProvider>
                <PlaceInfoRequestProvider>
                  <ContextHandler>
                    <Routes />
                  </ContextHandler>
                </PlaceInfoRequestProvider>
              </RouteProvider>
            </GoogleMapsProvider>
          </AuthProvider>
        </LoadProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
