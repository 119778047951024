import { useRef, useState } from "react";
import DashboardNavView from "./DashboardNavView";
import { useAuthContext } from "../../../contexts/AuthContext";
import useCustomEffect from "../../../hooks/useCustomEffect";

function DashboardNav() {
  const authContext = useAuthContext();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);

  /**
   * useCustomEffect for listening to the click event
   */
  useCustomEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  /**
   * This method will handle the click event
   * @param {Event} event
   */
  function handleClick(event) {
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      setOpen((current) => !current);
    } else {
      setOpen(false);
    }
  }

  /**
   * Handle the logout click
   */
  function onLogoutClick() {
    authContext.logout();
  }

  return (
    <DashboardNavView
      buttonRef={buttonRef}
      open={open}
      onLogoutClick={onLogoutClick}
    />
  );
}

export default DashboardNav;
