import styled from "styled-components";

export const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing.normal};
  align-items: start;

  @media (max-width: ${(props) => props.columnOn ?? "0px"}) {
    flex-direction: column;
    align-items: ${(props) => props.columnAlign ?? "initial"};
  }
`;

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledFlexItem = styled.div`
  flex: 1;
`;
