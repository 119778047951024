import { Route, Routes as RRDRoutes } from "react-router-dom";
import useLoginRouteConfig from "./configs/pages/LoginRouteConfig";
import useUsersRouteConfig from "./configs/pages/users/UsersRouteConfig";
import useUserCreateRouteConfig from "./configs/pages/users/UserCreateRouteConfig";
import useUserEditRouteConfig from "./configs/pages/users/UserEditRouteConfig";
import useNotFoundRouteConfig from "./configs/pages/NotFoundRouteConfig";
import useDashboardLayoutRouteConfig from "./configs/layouts/DashboardLayoutRouteConfig";
import usePlacesRouteConfig from "./configs/pages/places/PlacesRouteConfig";
import usePlaceCreateRouteConfig from "./configs/pages/places/PlaceCreateRouteConfig";
import usePlaceEditRouteConfig from "./configs/pages/places/PlaceEditRouteConfig";
import useSearchLogsRouteConfig from "./configs/pages/SearchLogsRouteConfig";

function Routes() {
  return (
    <RRDRoutes>
      {/* Login */}
      {useLoginRouteConfig().getRoute()}

      <Route
        path="dashboard"
        element={useDashboardLayoutRouteConfig().getRouteElement()}
      >
        {/* Users */}
        {useUsersRouteConfig().getRoute()}
        {useUserCreateRouteConfig().getRoute()}
        {useUserEditRouteConfig().getRoute()}

        {/* Places */}
        {usePlacesRouteConfig().getRoute()}
        {usePlaceCreateRouteConfig().getRoute()}
        {usePlaceEditRouteConfig().getRoute()}

        {/* Search logs */}
        {useSearchLogsRouteConfig().getRoute()}
      </Route>

      {/* Login */}
      {useNotFoundRouteConfig().getRoute()}
    </RRDRoutes>
  );
}

export default Routes;
