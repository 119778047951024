import styled from "styled-components";
import { StyledCard } from "../../styles/CardStyles";
import QRCode from "qrcode.react";

export const StyledCenteredPage = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.large};
  padding-top: ${(props) => props.theme.spacing.large};
`;

export const StyledLoginCard = styled(StyledCard)`
  width: 400px;
  max-width: 90%;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

export const StyledQrCode = styled(QRCode)`
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
