import PlacePage from "../../../../pages/places/place/PlacePage";
import { RouteConfig } from "../../../RouteConfig";
import usePlacesRouteValidator from "../../../validators/pages/places/PlacesRouteValidator";
import usePlacesRouteConfig from "./PlacesRouteConfig";

function usePlaceEditRouteConfig() {
  return new RouteConfig()
    .setPath("/dashboard/places/:place/edit")
    .setLabel("Plaats bewerken")
    .setValidator(usePlacesRouteValidator())
    .setPage(<PlacePage />)
    .setParent(usePlacesRouteConfig());
}

export default usePlaceEditRouteConfig;
