import { useTheme } from "styled-components";
import OverlayLoader from "../../../components/loader/overlay/OverlayLoader";
import { StyledDashboardContent } from "../../../layouts/dashboard/DashboardLayoutStyles";
import {
  StyledTable,
  StyledTableWrapper,
  StyledTd,
  StyledTh,
  StyledTr,
} from "../../../styles/TableStyles";
import InteractiveInput from "../../../components/interactiveInput/InteractiveInput";

function SearchLogsPageView({ searchLogs, search, onSearchChange }) {
  const theme = useTheme();

  return (
    <StyledDashboardContent>
      <InteractiveInput
        value={search}
        onChange={onSearchChange}
        placeholder="Zoeken..."
        style={{ maxWidth: "250px", marginBottom: theme.spacing.normal }}
      />

      <OverlayLoader rounded groups={"search_logs_page_fetch"}>
        <StyledTableWrapper>
          <StyledTable>
            <thead>
              <StyledTr>
                <StyledTh>Gebruiker</StyledTh>
                <StyledTh>Zoekterm</StyledTh>
                <StyledTh>Datum</StyledTh>
              </StyledTr>
            </thead>

            <tbody>
              {searchLogs.map((log) => (
                <StyledTr key={log.id}>
                  <StyledTd>{log.user_name}</StyledTd>
                  <StyledTd>{log.search}</StyledTd>
                  <StyledTd>{log.created_at}</StyledTd>
                </StyledTr>
              ))}

              {searchLogs.length === 0 && (
                <StyledTr>
                  <StyledTd colSpan={3} style={{ textAlign: "center" }}>
                    Geen zoekgeschiedenis gevonden
                  </StyledTd>
                </StyledTr>
              )}
            </tbody>
          </StyledTable>
        </StyledTableWrapper>
      </OverlayLoader>
    </StyledDashboardContent>
  );
}

export default SearchLogsPageView;
