import styled from "styled-components";
import { StyledCardHeader } from "../../../../styles/CardStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledHeader = styled(StyledCardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledFile = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  justify-content: space-between;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
    padding-bottom: ${(props) => props.theme.spacing.normal};
    margin-bottom: ${(props) => props.theme.spacing.normal};
  }
`;

export const StyledFileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledFileIcon = styled(FontAwesomeIcon)`
  width: 25px;
`;

export const StyledFileName = styled.p`
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.accent.dark};
  }

  @media (max-width: 768px) {
    max-width: 150px;
  }
`;
