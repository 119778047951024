import styled from "styled-components";

export const StyledOverlayLoader = styled.div`
  position: relative;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: ${(props) =>
    props.rounded === "true" ? props.theme.radius.normal : 0};
  z-index: 3;
`;
