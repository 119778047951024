import { useState } from "react";
import api from "../../../../apis/api";
import { useLoadContext } from "../../../../contexts/LoadContext";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import PlacePageFilesView from "./PlacePageFilesView";
import FileHelper from "../../../../helpers/FileHelper";
import { faFilePdf, faImage } from "@fortawesome/free-solid-svg-icons";
import { ApiHelper } from "../../../../helpers/ApiHelper";

function PlacePageFiles({ placeId }) {
  const loadContext = useLoadContext();
  const [files, setFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);

  /**
   * UseEffect to fetch the place files
   */
  useCustomEffect(() => {
    if (placeId) {
      fetchPlaceFiles();
    }
  }, []);

  /**
   * This method will fetch the place files from the API
   */
  async function fetchPlaceFiles() {
    //Enable the loader
    const loadTag = loadContext.enableLoader("place_page_files_fetch");

    try {
      //Fetch the files
      const response = await api.get(`places/${placeId}/files`);
      if (response.status === 200 && response.data?.success) {
        const newFiles = response.data.data;
        for (let i = 0; i < newFiles.length; i++) {
          newFiles[i].authenticatedPath = await FileHelper.apiFilePath(
            newFiles[i].path
          );
        }
        setFiles(newFiles);
      }
    } catch (error) {
    } finally {
      //Disable the loader
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will create a file for the place
   */
  async function createFile() {
    //Select the file
    const newFiles = await FileHelper.select(
      "application/pdf, image/jpeg, image/jpg, image/png",
      true
    );
    if (!newFiles || newFiles.length === 0) {
      return;
    }

    //Enable the loader
    const loadTag = loadContext.enableLoader("place_page_files_create");

    try {
      //Create the form data
      const formData = new FormData();
      formData.append("place_id", placeId);
      newFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      //Send the request
      const response = await api.post("place_files", formData);
      if (response.status === 200 && response.data?.success) {
        fetchPlaceFiles();
      }
    } catch (error) {
    } finally {
      //Disable the loader
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will delete the file from the API
   * @param {object} file
   */
  async function deleteFile(file) {
    //Ask for confirmation
    if (!window.confirm("Weet je zeker dat je dit bestand wilt verwijderen?")) {
      return;
    }

    //Enable the loader
    const loadTag = loadContext.enableLoader(
      `place_page_files_delete_${file.id}`
    );

    try {
      //Send the request
      await api.delete(`place_files/${file.id}`);
    } catch (error) {
    } finally {
      //Disable the loader and fetch the files
      fetchPlaceFiles();
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will update the file privacy
   * @param {object} file
   */
  async function updateFilePrivacy(file) {
    if (
      !window.confirm(
        "Weet je zeker dat je de privacy van dit bestand wilt wijzigen?"
      )
    ) {
      return;
    }

    //Enable the loader
    const loadTag = loadContext.enableLoader(
      `place_page_files_delete_${file.id}`
    );

    try {
      //Send the request
      const response = await api.post(
        `place_files/${file.id}`,
        ApiHelper.objectToFormData({ private: !file.private }, true)
      );
      if (response.status === 200 && response.data?.success) {
        fetchPlaceFiles();
      }
    } catch (error) {
    } finally {
      //Disable the loader
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will return the icon for the file
   * @param {object} file
   * @returns {IconDefinition}
   */
  function getFileIcon(file) {
    const extension = FileHelper.extension(file.path);
    if (extension === "pdf") {
      return faFilePdf;
    } else {
      return faImage;
    }
  }

  /**
   * This method will handle the file click
   * @param {object} file
   */
  function onFileClick(file) {
    setPreviewFile(file);
  }

  /**
   * This method will handle the preview close
   */
  function onPreviewClose() {
    setPreviewFile(null);
  }

  return (
    <PlacePageFilesView
      files={files}
      previewFile={previewFile}
      createFile={createFile}
      updateFilePrivacy={updateFilePrivacy}
      deleteFile={deleteFile}
      getFileIcon={getFileIcon}
      onFileClick={onFileClick}
      onPreviewClose={onPreviewClose}
    />
  );
}

export default PlacePageFiles;
