import styled from "styled-components";

export const StyledPlacesPageFilters = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 350px;
  background-color: ${(props) => props.theme.colors.primary.darkest};
  border-right: 1px solid ${(props) => props.theme.colors.primary.lightest};
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    max-width: 100%;
    border-right: none;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  margin-bottom: ${(props) => props.theme.spacing.large};
  margin-top: ${(props) => props.theme.spacing.normal};
  margin-inline: ${(props) => props.theme.spacing.large};
  padding-bottom: ${(props) => props.theme.spacing.normal};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
  padding-inline: ${(props) => props.theme.spacing.large};
  padding-bottom: ${(props) => props.theme.spacing.large};
  height: 100%;
  overflow-y: auto;
`;

export const StyledEnableFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};

  & > :last-child {
    flex: 1;
  }
`;
