class PlaceHelper {
  static getTypes() {
    return [
      "bed_and_breakfast",
      "campground",
      "hostel",
      "hotel",
      "motel",
      "real_estate",
    ];
  }

  static getStatuses() {
    return ["active", "capacity", "partner", "not_interested"];
  }

  static getStatusColor(status) {
    const colors = {
      unknown: { background: "white", text: "black" },
      active: { background: "#10b981", text: "white" },
      capacity: { background: "#eab308", text: "white" },
      partner: { background: "#818cf8", text: "white" },
      not_interested: { background: "#f43f5e", text: "white" },
    };

    return colors[status] || colors.unknown;
  }
}

export default PlaceHelper;
