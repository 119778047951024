import { useRef, useState } from "react";
import { useGoogleMapsContext } from "../contexts/GoogleMapsContext";
import useCustomEffect from "./useCustomEffect";

function useGoogleMaps() {
  const { libraries } = useGoogleMapsContext();
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const initialMapOptions = {
    center: { lat: 52.092876, lng: 5.10448 },
    zoom: 8,
  };

  //UseEffect to create the Google Map automatically
  useCustomEffect(() => {
    createGoogleMap();
  }, [mapRef.current]);

  /**
   * This method will initialize the Google Map
   */
  function createGoogleMap() {
    if (!mapRef.current || map) return;

    const newMap = new libraries.maps.Map(mapRef.current, {
      mapId: Date.now().toString(),
      disableDefaultUI: true,
      center: initialMapOptions.center,
      zoom: initialMapOptions.zoom,
      minZoom: 6,
      maxZoom: 18,
    });

    setMap(newMap);
  }

  /**
   * This method will convert the location object to a Google Maps location object
   * @param {{ latitude, longitude }} location
   * @returns {{ lat, lng }}
   */
  function getGeoLocation(location) {
    return {
      lat: location.latitude ?? location.lat,
      lng: location.longitude ?? location.lng,
    };
  }

  /**
   * This method will move the map to the given position and zoom
   * @param {{ lat, lng }} position
   * @param {int | null} zoom
   */
  function goToPosition(position, zoom = null) {
    if (!map) return;

    map.panTo(position);
    if (zoom) {
      map.setZoom(zoom);
    }
  }

  return {
    map,
    mapRef,
    initialMapOptions,
    getGeoLocation,
    goToPosition,
  };
}

export default useGoogleMaps;
