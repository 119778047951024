import styled from "styled-components";
import { StyledIconButton } from "../../styles/InputStyles";

export const StyledUsersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.normal};
  margin-top: ${(props) => props.theme.spacing.normal};
`;

export const StyledCreateButton = styled(StyledIconButton)`
  margin-left: auto;
`;

export const StyledUserCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  background-color: ${(props) => props.theme.colors.primary.normal};
  box-shadow: 10px 10px 30px -10px rgba(0, 0, 0, 0.3);
`;

export const StyledUserName = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 500px) {
    max-width: 125px;
  }
`;

export const StyledUserRole = styled.span`
  font-weight: bold;
  background-color: ${(props) => props.theme.colors.primary.lightest};
  padding: ${(props) => props.theme.spacing.small};
  border-radius: ${(props) => props.theme.radius.normal};
  display: flex;
  align-items: center;
  justify-content: center;
`;
