export class AddressHelper {
  static getFullAddress(address, ignore = []) {
    let fullAddress = "";
    if (address.address && !ignore.includes("address")) {
      fullAddress += address.address;
    }
    if (address.postal_code && !ignore.includes("postal_code")) {
      fullAddress += (address.address ? ", " : "") + address.postal_code;
    }
    if (address.city && !ignore.includes("city")) {
      fullAddress += (address.postal_code ? " " : ", ") + address.city;
    }
    if (address.state && !ignore.includes("state")) {
      fullAddress += ", " + address.state;
    }
    if (address.country && !ignore.includes("country")) {
      fullAddress += ", " + address.country;
    }
    return fullAddress;
  }
}
