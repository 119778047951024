import PlacesPageMapView from "./PlacesPageMapView";

function PlacesPageMap({
  googleMaps,
  placesData,
  setPlacesData,
  filtersController,
}) {
  /**
   * This method will determine where the map is located and zoomed to
   * @returns {{ position: { lat: number, lng: number }, zoom: number }}
   */
  function getGoogleMapsPositionData() {
    if (placesData.previewPlace) {
      return {
        position: googleMaps.getGeoLocation(placesData.previewPlace.location),
        zoom: 17,
      };
    } else if (placesData.location) {
      return {
        position: googleMaps.getGeoLocation(placesData.location),
        zoom: 12,
      };
    } else {
      return {
        position: googleMaps.getGeoLocation(
          googleMaps.initialMapOptions.center
        ),
        zoom: googleMaps.initialMapOptions.zoom,
      };
    }
  }

  /**
   * This method will handle the place marker click
   * @param {object} place
   */
  function onPlaceMarkerClick(place) {
    setPlacesData((current) => ({
      ...current,
      previewPlace: place,
    }));
  }

  return (
    <PlacesPageMapView
      googleMaps={googleMaps}
      placesData={placesData}
      filtersController={filtersController}
      googleMapsPositionData={getGoogleMapsPositionData()}
      onPlaceMarkerClick={onPlaceMarkerClick}
    />
  );
}

export default PlacesPageMap;
