import LoginPage from "../../../pages/login/LoginPage";
import useLoginRouteValidator from "../../validators/pages/LoginRouteValidator";
import { RouteConfig } from "../../RouteConfig";

function useLoginRouteConfig() {
  return new RouteConfig()
    .setPath("/login")
    .setLabel("Inloggen")
    .setValidator(useLoginRouteValidator())
    .setPage(<LoginPage />);
}

export default useLoginRouteConfig;
