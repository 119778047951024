import useCustomEffect from "../../hooks/useCustomEffect";

function GoogleMap({ controller, position, zoom, children }) {
  position = position || controller.initialMapOptions.center;
  zoom = zoom || controller.initialMapOptions.zoom;

  /**
   * UseEffect to update the map position and zoom
   */
  useCustomEffect(() => {
    if (controller.map && position) {
      controller.goToPosition(position, zoom);
    }
  }, [controller.map, position.lat, position.lng, zoom]);

  return (
    <div ref={controller.mapRef} style={{ width: "100%", height: "100%" }}>
      {children}
    </div>
  );
}

export default GoogleMap;
