import { useEffect } from "react";

/* eslint-disable react-hooks/exhaustive-deps */

/**
 * Custom useEffect hook that ignores warnings.
 * @param {function} callback - Function to be executed.
 * @param {array} dependencies - Dependencies to watch for changes.
 */
function useCustomEffect(callback, dependencies = []) {
  useEffect(() => {
    const cleanupFunction = callback();

    if (typeof cleanupFunction === "function") {
      return cleanupFunction;
    }
  }, dependencies);
}

/* eslint-enable react-hooks/exhaustive-deps */

export default useCustomEffect;
