class TimeHelper {
  static getDate(datetime) {
    const dateWithTime =
      datetime instanceof Date ? datetime : new Date(datetime);
    const date = new Date(
      dateWithTime.getFullYear(),
      dateWithTime.getMonth(),
      dateWithTime.getDate()
    );
    date.setHours(0, 0, 0, 0);
    return date;
  }
}

export default TimeHelper;
