import GenericStyles from "./GenericStyles";
import InputStyles from "./InputStyles";

function GlobalStyles() {
  return (
    <>
      <GenericStyles />
      <InputStyles />
    </>
  );
}

export default GlobalStyles;
