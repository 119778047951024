import SelectView from "./SelectView";

function Select({
  as,
  options,
  onChange,
  value,
  disabled,
  title,
  valueVisual,
  hideCaret,
}) {
  const selectedOption = options?.find(
    (option) => `${option.value}` === `${value}`
  );

  /**
   * Handle the onChange event
   * @param {event} e
   */
  function handleChange(e) {
    if (onChange) {
      const newValue = options?.find(
        (option) => `${option.value}` === e.target.value
      )?.value;
      onChange(newValue);
    }
  }

  return (
    <SelectView
      as={as}
      disabled={disabled}
      valueVisual={valueVisual}
      value={value}
      selectedOption={selectedOption}
      options={options}
      onChange={handleChange}
      hideCaret={hideCaret}
      title={title}
    />
  );
}

export default Select;
