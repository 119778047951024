import UserPage from "../../../../pages/users/user/UserPage";
import { RouteConfig } from "../../../RouteConfig";
import useUserCreateRouteValidator from "../../../validators/pages/users/UserCreateRouteValidator";
import useUsersRouteConfig from "./UsersRouteConfig";

function useUserCreateRouteConfig() {
  return new RouteConfig()
    .setPath("/dashboard/users/create")
    .setLabel("Gebruiker aanmaken")
    .setValidator(useUserCreateRouteValidator())
    .setPage(<UserPage />)
    .setParent(useUsersRouteConfig());
}

export default useUserCreateRouteConfig;
