import useAuthMiddleware from "../../../../middlewares/AuthMiddleware.js";
import useMiddlewares from "../../../../middlewares/Middleware.js";
import useRoleMiddleware from "../../../../middlewares/RoleMiddleware.js";
import useRouteValidator from "../../../RouteValidator.js";

function useUserCreateRouteValidator() {
  return useRouteValidator(
    useMiddlewares([useAuthMiddleware(), useRoleMiddleware("admin")])
  );
}

export default useUserCreateRouteValidator;
