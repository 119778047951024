import PlacePage from "../../../../pages/places/place/PlacePage";
import { RouteConfig } from "../../../RouteConfig";
import usePlaceCreateRouteValidator from "../../../validators/pages/places/PlaceCreateRouteValidator";
import usePlacesRouteConfig from "./PlacesRouteConfig";

function usePlaceCreateRouteConfig() {
  return new RouteConfig()
    .setPath("/dashboard/places/create")
    .setLabel("Plaats aanmaken")
    .setValidator(usePlaceCreateRouteValidator())
    .setPage(<PlacePage />)
    .setParent(usePlacesRouteConfig());
}

export default usePlaceCreateRouteConfig;
