import { useNavigate } from "react-router-dom";
import PlacesPageSearchBarView from "./PlacesPageSearchBarView";

function PlacesPageSearchBar({ placesData, filtersController, setPlacesData }) {
  const navigate = useNavigate();

  /**
   * This method will handle the confirm search
   */
  function onConfirmSearch() {
    filtersController.fetchPlaces();
  }

  /**
   * This method will handle the show map change
   * @param {boolean} value
   */
  function onShowMapChange(value) {
    if (placesData.showMap !== value) {
      setPlacesData((current) => ({
        ...current,
        showMap: value,
        previewPlace: value === false ? null : current.previewPlace,
      }));
    }
  }

  /**
   * This method will handle the filters click
   */
  function onFiltersClick() {
    setPlacesData((current) => ({
      ...current,
      showFilters: !current.showFilters,
    }));
  }

  /**
   * This method will handle the add click
   */
  function onAddClick() {
    navigate(`/dashboard/places/create`, {
      state: {
        placesPage: {
          data: placesData,
          filters: filtersController.filters,
        },
      },
    });
  }

  return (
    <PlacesPageSearchBarView
      placesData={placesData}
      filtersController={filtersController}
      onShowMapChange={onShowMapChange}
      onFiltersClick={onFiltersClick}
      onConfirmSearch={onConfirmSearch}
      onAddClick={onAddClick}
    />
  );
}

export default PlacesPageSearchBar;
