import { createContext, useContext, useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import ScreenLoader from "../components/loader/screen/ScreenLoader";

const LoadContext = createContext();

export function LoadProvider({ children }) {
  const [groups, setGroups] = useState([]);
  const tagsRef = useRef([]);

  /**
   * This method will generate a unique loading tag
   * @returns {string}
   */
  const generateLoadTag = () => {
    const timestamp = Date.now().toString(36);
    const randomValue = Math.random().toString(36).substr(2, 5);
    return timestamp + randomValue;
  };

  /**
   * This method will add the given tag to the loading state
   * @param {string} group
   * @returns {string|null}
   */
  const enableLoader = useCallback((group) => {
    if (!group) {
      return null;
    }

    const tag = `${group}____${generateLoadTag()}`;
    const firstFromGroup = !tagsRef.current.find((t) => t.startsWith(group));
    tagsRef.current.push(tag);

    if (firstFromGroup) {
      setGroups((prevGroups) => [...prevGroups, group]);
    }

    return tag;
  }, []);

  /**
   * This method will remove the given tag from the loading state
   * @param {string} tag
   */
  const disableLoader = useCallback((tag) => {
    if (!tag) {
      return;
    }

    tagsRef.current = tagsRef.current.filter((t) => t !== tag);

    const [group] = tag.split("____");
    const lastFromGroup = !tagsRef.current.find((t) => t.startsWith(group));

    if (lastFromGroup) {
      setGroups((prevGroups) => prevGroups.filter((g) => g !== group));
    }
  }, []);

  /**
   * This method will return if the given group is currently loading
   * @param {string | string[]} grps
   * @returns {boolean}
   */
  const isLoading = useCallback(
    (grps) => {
      if (!grps) {
        return false;
      }

      if (Array.isArray(grps)) {
        for (let i = 0; i < grps.length; i++) {
          if (groups.includes(groups[i])) {
            return true;
          }
        }

        return false;
      } else {
        return groups.includes(grps);
      }
    },
    [groups]
  );

  return (
    <LoadContext.Provider value={{ isLoading, enableLoader, disableLoader }}>
      {children}
      <ScreenLoader groups="APP" />
    </LoadContext.Provider>
  );
}

export function useLoadContext() {
  return useContext(LoadContext);
}
