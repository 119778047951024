import { useLocation, useNavigate, useParams } from "react-router-dom";
import PlacePageView from "./PlacePageView";
import { useRouteContext } from "../../../contexts/RouteContext";
import { useState } from "react";
import { useLoadContext } from "../../../contexts/LoadContext";
import useCustomEffect from "../../../hooks/useCustomEffect";
import api from "../../../apis/api";
import { ApiHelper } from "../../../helpers/ApiHelper";

function PlacePage() {
  const { place } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const loadContext = useLoadContext();
  const routeContext = useRouteContext();
  const [errors, setErrors] = useState(null);
  const [placeData, setPlaceData] = useState({
    name: state?.place?.name || "",
    type: state?.place?.type || "real_estate",
    status: "partner",
    country: state?.place?.country || "Nederland",
    state: state?.place?.state || "",
    city: state?.place?.city || "",
    address: state?.place?.address || "",
    postal_code: state?.place?.postal_code || "",
    latitude: state?.place?.location?.latitude || "",
    longitude: state?.place?.location?.longitude || "",
    phone_number: state?.place?.phone_number || "",
    available_room_count: state?.place?.available_room_count || 0,
    available_person_count: state?.place?.available_person_count || 0,
    booked_room_count: state?.place?.booked_room_count || 0,
    booked_person_count: state?.place?.booked_person_count || 0,
    contact_name: state?.place?.contact_name || "",
    contact_position: state?.place?.contact_position || "",
    contact_email: state?.place?.contact_email || "",
    contact_phone: state?.place?.contact_phone || "",
    available_from: state?.place?.available_from || "",
    available_until: state?.place?.available_until || "",
    details: state?.place?.details || "",
  });

  /**
   * UseEffect to fetch the place data
   */
  useCustomEffect(() => {
    if (place) {
      fetchPlace();
    }
  }, [place]);

  /**
   * This method will fetch the place from the API
   */
  async function fetchPlace() {
    const loadTag = loadContext.enableLoader("place_page_fetch");

    try {
      const response = await api.get(`places/${place}`);
      if (response.status === 200 && response.data?.success) {
        const newPlaceData = response.data.data;
        setPlaceData({
          name: newPlaceData.name,
          type: newPlaceData.type,
          status: newPlaceData.status,
          country: newPlaceData.country,
          state: newPlaceData.state,
          city: newPlaceData.city,
          address: newPlaceData.address,
          postal_code: newPlaceData.postal_code,
          latitude: newPlaceData.location.latitude,
          longitude: newPlaceData.location.longitude,
          phone_number: newPlaceData.phone_number,
          available_room_count: newPlaceData.available_room_count,
          available_person_count: newPlaceData.available_person_count,
          booked_room_count: newPlaceData.booked_room_count,
          booked_person_count: newPlaceData.booked_person_count,
          contact_name: newPlaceData.contact_name,
          contact_position: newPlaceData.contact_position,
          contact_email: newPlaceData.contact_email,
          contact_phone: newPlaceData.contact_phone,
          available_from: newPlaceData.available_from,
          available_until: newPlaceData.available_until,
          details: newPlaceData.details,
        });
      }
    } catch (error) {
      navigate(routeContext.routeConfig.previousStep.path, {
        state: state,
      });
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will handle the name change
   * @param {Event} e
   */
  function onNameChange(e) {
    setPlaceData((current) => ({
      ...current,
      name: e.target.value,
    }));
  }

  /**
   * This method will handle the type change
   * @param {any} value
   */
  function onTypeChange(value) {
    setPlaceData((current) => ({
      ...current,
      type: value,
    }));
  }

  /**
   * This method will handle the status change
   * @param {any} value
   */
  function onStatusChange(value) {
    setPlaceData((current) => ({
      ...current,
      status: value,
    }));
  }

  /**
   * This method will handle the state change
   * @param {Event} e
   */
  function onStateChange(e) {
    setPlaceData((current) => ({
      ...current,
      state: e.target.value,
    }));
  }

  /**
   * This method will handle the city change
   * @param {Event} e
   */
  function onCityChange(e) {
    setPlaceData((current) => ({
      ...current,
      city: e.target.value,
    }));
  }

  /**
   * This method will handle the address change
   * @param {Event} e
   */
  function onAddressChange(e) {
    setPlaceData((current) => ({
      ...current,
      address: e.target.value,
    }));
  }

  /**
   * This method will handle the postal code change
   * @param {Event} e
   */
  function onPostalCodeChange(e) {
    setPlaceData((current) => ({
      ...current,
      postal_code: e.target.value,
    }));
  }

  /**
   * This method will handle the phone number change
   * @param {Event} e
   */
  function onPhoneNumberChange(e) {
    setPlaceData((current) => ({
      ...current,
      phone_number: e.target.value,
    }));
  }

  /**
   * This method will handle the room count change
   * @param {Event} e
   */
  function onAvailableRoomCountChange(e) {
    setPlaceData((current) => ({
      ...current,
      available_room_count: e.target.value,
    }));
  }

  /**
   * This method will handle the person count change
   * @param {Event} e
   */
  function onAvailablePersonCountChange(e) {
    setPlaceData((current) => ({
      ...current,
      available_person_count: e.target.value,
    }));
  }

  /**
   * This method will handle the booked room count change
   * @param {Event} e
   */
  function onBookedRoomCountChange(e) {
    setPlaceData((current) => ({
      ...current,
      booked_room_count: e.target.value,
    }));
  }

  /**
   * This method will handle the booked person count change
   * @param {Event} e
   */
  function onBookedPersonCountChange(e) {
    setPlaceData((current) => ({
      ...current,
      booked_person_count: e.target.value,
    }));
  }

  /**
   * This method will handle the contact name change
   * @param {Event} e
   */
  function onContactNameChange(e) {
    setPlaceData((current) => ({
      ...current,
      contact_name: e.target.value,
    }));
  }

  /**
   * This method will handle the contact position change
   * @param {Event} e
   */
  function onContactPositionChange(e) {
    setPlaceData((current) => ({
      ...current,
      contact_position: e.target.value,
    }));
  }

  /**
   * This method will handle the contact email change
   * @param {Event} e
   */
  function onContactEmailChange(e) {
    setPlaceData((current) => ({
      ...current,
      contact_email: e.target.value,
    }));
  }

  /**
   * This method will handle the contact phone change
   * @param {Event} e
   */
  function onContactPhoneChange(e) {
    setPlaceData((current) => ({
      ...current,
      contact_phone: e.target.value,
    }));
  }

  /**
   * This method will handle the available from change
   * @param {Event} e
   */
  function onAvailableFromChange(e) {
    setPlaceData((current) => ({
      ...current,
      available_from: e.target.value,
    }));
  }

  /**
   * This method will handle the available until change
   * @param {Event} e
   */
  function onAvailableUntilChange(e) {
    setPlaceData((current) => ({
      ...current,
      available_until: e.target.value,
    }));
  }

  /**
   * This method will handle the details change
   * @param {Event} e
   */
  function onDetailsChange(e) {
    setPlaceData((current) => ({
      ...current,
      details: e.target.value,
    }));
  }

  /**
   * This method will handle the cancel click
   */
  function onCancel() {
    navigate(routeContext.routeConfig.previousStep.path, {
      state: state,
    });
  }

  /**
   * This method will handle the submit
   */
  async function onSubmit() {
    setErrors(null);
    const loadTag = loadContext.enableLoader("place_page_submit");

    try {
      const response = await api.post(
        place ? `places/${place}` : "places",
        ApiHelper.objectToFormData(placeData, place),
        {
          params: { with_place_user_data: true },
        }
      );

      if (response.status === 200 && response.data?.success) {
        navigate(routeContext.routeConfig.previousStep.path, {
          state: state,
        });
      }
    } catch (error) {
      setErrors(ApiHelper.getResponseErrors(error));
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  return (
    (state || place) && (
      <PlacePageView
        place={place}
        placeData={placeData}
        onNameChange={onNameChange}
        onTypeChange={onTypeChange}
        onStatusChange={onStatusChange}
        onStateChange={onStateChange}
        onCityChange={onCityChange}
        onAddressChange={onAddressChange}
        onPostalCodeChange={onPostalCodeChange}
        onPhoneNumberChange={onPhoneNumberChange}
        onAvailableRoomCountChange={onAvailableRoomCountChange}
        onAvailablePersonCountChange={onAvailablePersonCountChange}
        onBookedRoomCountChange={onBookedRoomCountChange}
        onBookedPersonCountChange={onBookedPersonCountChange}
        onContactNameChange={onContactNameChange}
        onContactPositionChange={onContactPositionChange}
        onContactEmailChange={onContactEmailChange}
        onContactPhoneChange={onContactPhoneChange}
        onAvailableFromChange={onAvailableFromChange}
        onAvailableUntilChange={onAvailableUntilChange}
        onDetailsChange={onDetailsChange}
        onCancel={onCancel}
        onSubmit={onSubmit}
        errors={errors}
      />
    )
  );
}

export default PlacePage;
