import { useTheme } from "styled-components";
import {
  StyledCardBody,
  StyledCardHeader,
  StyledCardTitle,
} from "../../styles/CardStyles";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../styles/LayoutStyles";
import {
  StyledCenteredPage,
  StyledInputContainer,
  StyledLoginCard,
  StyledQrCode,
} from "./LoginPageStyles";
import InputWrapper from "../../components/inputWrapper/InputWrapper";
import { StyledError } from "../../styles/ErrorStyles";

function LoginPageView({
  loginData,
  tfaQRCodeUrl,
  onEmailChange,
  onPasswordChange,
  onTfaCodeChange,
  onQrCodeClick,
  onSubmit,
  errors,
}) {
  const theme = useTheme();

  return (
    <StyledCenteredPage>
      <h1>Space Finder</h1>

      {/* Login card */}
      <StyledLoginCard>
        <StyledCardHeader>
          <StyledCardTitle>Inloggen</StyledCardTitle>
        </StyledCardHeader>

        <StyledCardBody>
          <StyledInputContainer>
            {/* Email */}
            <InputWrapper label={"Email"} required error={errors?.email}>
              <input
                type="email"
                placeholder="Email"
                value={loginData.email}
                onChange={onEmailChange}
              />
            </InputWrapper>

            {/* Password */}
            <InputWrapper
              label={"Wachtwoord"}
              required
              error={errors?.password}
            >
              <input
                type="password"
                placeholder="******"
                value={loginData.password}
                onChange={onPasswordChange}
              />
            </InputWrapper>

            {/* TFA Code */}
            <InputWrapper label={"TFA Code"} error={errors?.tfa_code}>
              <input
                type="password"
                placeholder="******"
                value={loginData.tfa_code}
                onChange={onTfaCodeChange}
              />
            </InputWrapper>
          </StyledInputContainer>

          <StyledFlexColumn
            style={{ alignItems: "start", gap: theme.spacing.small }}
          >
            <button onClick={onSubmit}>Ga verder</button>
            <StyledError>{errors?.message}</StyledError>
          </StyledFlexColumn>
        </StyledCardBody>
      </StyledLoginCard>

      {/* Two factor authentication card */}
      {tfaQRCodeUrl && (
        <StyledLoginCard>
          <StyledCardBody>
            <StyledFlexRow>
              <StyledQrCode onClick={onQrCodeClick} value={tfaQRCodeUrl} />

              <StyledFlexItem>
                <p>
                  Twee factor authenticatie is geactiveerd voor uw account. Scan
                  de QR code met een authenticator app op uw telefoon. Voer
                  voortaan de gegenereerde code in bij het inloggen onder TFA
                  Code.
                </p>
              </StyledFlexItem>
            </StyledFlexRow>

            <StyledFlexRow style={{ marginTop: theme.spacing.normal }}>
              <StyledFlexItem>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/google_play_button.png"
                    alt="Google Play"
                  />
                </a>
              </StyledFlexItem>

              <StyledFlexItem>
                <a
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/apple_store_button.png"
                    alt="Apple Store"
                  />
                </a>
              </StyledFlexItem>
            </StyledFlexRow>
          </StyledCardBody>
        </StyledLoginCard>
      )}
    </StyledCenteredPage>
  );
}

export default LoginPageView;
