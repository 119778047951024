import { Navigate } from "react-router";

class LayoutRouteConfig {
  constructor() {
    this.layout = null;
    this.validator = null;
  }

  setLayout(layout) {
    this.layout = layout;
    return this;
  }

  setValidator(validator) {
    this.validator = validator;
    return this;
  }

  getRouteElement() {
    const validation = this.validator ? this.validator.validate() : true;
    return validation === true ? (
      this.layout
    ) : (
      <Navigate to={validation.to} replace={validation.replace} />
    );
  }
}

export default LayoutRouteConfig;
