import { useTheme } from "styled-components";
import InputWrapper from "../../../components/inputWrapper/InputWrapper";
import Select from "../../../components/select/main/Select";
import { StyledError } from "../../../styles/ErrorStyles";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../../styles/LayoutStyles";
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../../../styles/CardStyles";
import { StyledAltButton } from "../../../styles/InputStyles";
import HiddenLoader from "../../../components/loader/hidden/HiddenLoader";
import OverlayLoader from "../../../components/loader/overlay/OverlayLoader";
import { StyledDashboardContent } from "../../../layouts/dashboard/DashboardLayoutStyles";
import { useRouteContext } from "../../../contexts/RouteContext";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { useAuthContext } from "../../../contexts/AuthContext";

function UserPageView({
  user,
  userData,
  onNameChange,
  onEmailChange,
  onPasswordChange,
  onRoleChange,
  onPhoneNumberChange,
  onCancel,
  onSubmit,
  errors,
}) {
  const theme = useTheme();
  const routeContext = useRouteContext();
  const authContext = useAuthContext();

  return (
    <StyledDashboardContent>
      <StyledCard>
        <StyledCardHeader>
          <h2>{routeContext.routeConfig.label}</h2>
        </StyledCardHeader>

        <OverlayLoader groups={"user_page_fetch"}>
          <StyledCardBody>
            <StyledFlexColumn>
              <InputWrapper label="Naam" required error={errors?.name}>
                <input
                  type="text"
                  value={userData.name}
                  onChange={onNameChange}
                  placeholder="Naam"
                />
              </InputWrapper>

              <InputWrapper label="Rol" required error={errors?.role}>
                <Select
                  disabled={authContext.auth?.user?.role !== "admin"}
                  value={userData.role}
                  options={[
                    {
                      value: "user",
                      label: LanguageHelper.translateRole("user"),
                    },
                    {
                      value: "admin",
                      label: LanguageHelper.translateRole("admin"),
                    },
                  ]}
                  onChange={onRoleChange}
                />
              </InputWrapper>

              <InputWrapper label="Email" required error={errors?.email}>
                <input
                  type="email"
                  value={userData.email}
                  onChange={onEmailChange}
                  placeholder="voorbeeld@email.com"
                />
              </InputWrapper>

              <InputWrapper
                label="Wachtwoord"
                required={!user}
                error={errors?.password}
              >
                <input
                  type="password"
                  value={userData.password}
                  onChange={onPasswordChange}
                  placeholder="******"
                />
              </InputWrapper>

              <InputWrapper label="Telefoonnummer" error={errors?.phone_number}>
                <input
                  type="text"
                  value={userData.phone_number}
                  onChange={onPhoneNumberChange}
                  placeholder="06-12345678"
                />
              </InputWrapper>
            </StyledFlexColumn>

            <StyledFlexColumn style={{ marginTop: theme.spacing.large }}>
              <StyledFlexRow>
                <StyledAltButton onClick={onCancel}>Annuleer</StyledAltButton>

                <HiddenLoader groups={"user_page_submit"}>
                  <button onClick={onSubmit}>Opslaan</button>
                </HiddenLoader>
              </StyledFlexRow>

              {errors?.message && <StyledError>{errors?.message}</StyledError>}
            </StyledFlexColumn>
          </StyledCardBody>
        </OverlayLoader>
      </StyledCard>
    </StyledDashboardContent>
  );
}

export default UserPageView;
