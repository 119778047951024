import styled from "styled-components";

export const StyledCard = styled.div`
  background-color: ${(props) => props.theme.colors.primary.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  box-shadow: 10px 10px 30px -10px rgba(0, 0, 0, 0.3);
`;

export const StyledCardHeader = styled.div`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  border-top-left-radius: ${(props) => props.theme.radius.normal};
  border-top-right-radius: ${(props) => props.theme.radius.normal};
  padding: ${(props) => props.theme.spacing.normal};

  &:last-child {
    border-bottom-left-radius: ${(props) => props.theme.radius.normal};
    border-bottom-right-radius: ${(props) => props.theme.radius.normal};
  }
`;

export const StyledCardTitle = styled.h2`
  margin: 0 auto;
  display: block;
`;

export const StyledCardBody = styled.div`
  padding: ${(props) => props.theme.spacing.normal};
`;
