import { useState } from "react";
import UsersPageView from "./UsersPageView";
import api from "../../apis/api";
import { useLoadContext } from "../../contexts/LoadContext";
import useCustomEffect from "../../hooks/useCustomEffect";
import { useAuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function UsersPage() {
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const loadContext = useLoadContext();
  const [users, setUsers] = useState([]);

  /**
   * Initialize the component
   */
  useCustomEffect(() => {
    fetchUsers();
  });

  /**
   * This method will fetch the users from the API
   */
  async function fetchUsers() {
    const loadTag = loadContext.enableLoader("users_page_fetch");

    try {
      const response = await api.get("users");
      if (response.status === 200 && response.data?.success) {
        setUsers(response.data.data);
      }
    } catch (error) {
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will handle the create click
   */
  function onCreateClick() {
    navigate("/dashboard/users/create");
  }

  /**
   * This method will handle the edit click
   * @param {string | int} id
   */
  function onEditClick(id) {
    navigate(`/dashboard/users/${id}/edit`);
  }

  /**
   * This method will delete the user with the given id
   * @param {string | int} id
   */
  async function onDeleteClick(id) {
    if (
      !window.confirm("Weet je zeker dat je deze gebruiker wilt verwijderen?")
    ) {
      return;
    }

    const loadTag = loadContext.enableLoader(`users_page_delete_${id}`);

    try {
      const response = await api.delete(`users/${id}`);
      if (response.status === 200 && response.data?.success) {
        fetchUsers();
      }
    } catch (error) {
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will return if the delete button should be disabled for the given id
   * @param {string | int} id
   * @returns {boolean}
   */
  function deleteDisabled(id) {
    return (
      id.toString() === authContext.auth.user.id.toString() ||
      authContext.auth.user.role !== "admin"
    );
  }

  return (
    <UsersPageView
      users={users}
      onCreateClick={onCreateClick}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      deleteDisabled={deleteDisabled}
    />
  );
}

export default UsersPage;
