import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useLoadContext } from "./LoadContext";
import api from "../apis/api";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const loadContext = useLoadContext();
  const [cookies, setCookie, removeCookie] = useCookies([
    "user_id",
    "jwt_token",
  ]);
  const [auth, setAuth] = useState({
    user: null,
    token: null,
  });
  const [fetchingUser, setFetchingUser] = useState(true);

  /**
   * This method will fetch the user from the API
   */
  async function fetchUser() {
    if (cookies.user_id) {
      const loadTag = loadContext.enableLoader("APP");
      setFetchingUser(true);

      try {
        const response = await api.get(`users/${cookies.user_id}`);
        if (response.status === 200 && response.data?.success) {
          const user = response.data.data;

          setAuth({
            user: user,
            token: cookies.jwt_token,
          });
        }
      } catch (error) {
        logout();
      } finally {
        setFetchingUser(false);
        loadContext.disableLoader(loadTag);
      }
    } else {
      setFetchingUser(false);
    }
  }

  /**
   * This method will try to login to user with the given credentials
   *
   * @param {object} loginData
   * @param {function(JSONResponse)} onSuccess
   * @param {function(JSONResponse)} onError
   * @returns {JSONResponse}
   */
  async function login(loginData, onSuccess = null, onError = null) {
    const loadTag = loadContext.enableLoader("APP");

    try {
      const response = await api.post("login", loginData);

      if (response.status === 200 && response.data?.success) {
        const user = response.data.data.user;
        const token = response.data.data.token;

        setCookie("user_id", user.id, { path: "/", maxAge: 9999999999 });
        setCookie("jwt_token", token, { path: "/", maxAge: 9999999999 });

        setAuth({
          user: user,
          token: token,
        });
      }

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will try to logout the current authenticated user
   * @param {function(JSONResponse)} onSuccess
   * @param {function(JSONResponse)} onError
   * @returns {JSONResponse}
   */
  async function logout(onSuccess = null, onError = null) {
    const loadTag = loadContext.enableLoader("APP");

    try {
      const response = await api.post("logout");

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      setAuth({ user: null, token: null });
      removeCookie("user_id", { path: "/" });
      removeCookie("jwt_token", { path: "/" });
      loadContext.disableLoader(loadTag);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        fetchingUser,
        fetchUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
