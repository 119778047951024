import { useState } from "react";
import api from "../../../apis/api";
import { useLoadContext } from "../../../contexts/LoadContext";
import useGoogleMaps from "../../../hooks/useGoogleMaps";
import PlacesPageView from "./PlacesPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useLocation } from "react-router-dom";
import usePlacesPageFilters from "./filters/usePlacesPageFilters";

function PlacesPage() {
  const { state } = useLocation();
  const loadContext = useLoadContext();
  const googleMaps = useGoogleMaps();
  const [placesData, setPlacesData] = useState({
    showMap: state?.placesPage?.data?.showMap ?? false,
    showFilters: state?.placesPage?.data?.showFilters ?? false,
    previewPlace: null,
    location: state?.placesPage?.data?.location ?? null,
    places: state?.placesPage?.data?.places ?? [],
    google_places_count: state?.placesPage?.data?.google_places_count ?? 0,
  });
  const filtersController = usePlacesPageFilters(
    placesData,
    fetchPlaces,
    setPlacesData
  );

  /**
   * UseEffect for the initial fetch
   */
  useCustomEffect(() => {
    //Delete the state
    window.history.replaceState(null, "");

    fetchPlaces(false);
  }, []);

  /**
   * This method will fetch the places from the API
   */
  async function fetchPlaces(switchMap = true) {
    if (loadContext.isLoading("places_page_fetch")) {
      return;
    }

    const loadTag = loadContext.enableLoader("places_page_fetch");

    try {
      const response = await api.get("places", {
        params: {
          ...filtersController.filters,
          with_place_user_data: true,
        },
      });

      if (response.status === 200 && response.data?.success) {
        const newLocation = response.data.data.location;
        const newPlaces = response.data.data.places;

        setPlacesData((current) => ({
          ...current,
          location: newLocation,
          places: newPlaces,
          google_places_count: response.data.data.google_places_count,
          showMap: switchMap
            ? filtersController.filters.text_search.length !== 0
            : current.showMap,
          previewPlace: null,
        }));
      }
    } catch (error) {
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  return (
    <PlacesPageView
      googleMaps={googleMaps}
      placesData={placesData}
      filtersController={filtersController}
      setPlacesData={setPlacesData}
      fetchPlaces={fetchPlaces}
    />
  );
}

export default PlacesPage;
