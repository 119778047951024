import { useState } from "react";
import FoldableCardView from "./FoldableCardView";

function FoldableCard({ title, children }) {
  const [folded, setFolded] = useState(true);
  return (
    <FoldableCardView title={title} folded={folded} setFolded={setFolded}>
      {children}
    </FoldableCardView>
  );
}

export default FoldableCard;
