import InputWrapperView from "./InputWrapperView";

function InputWrapper({ label, required, error, children, style }) {
  return (
    <InputWrapperView
      label={label}
      error={error}
      required={required}
      style={style}
    >
      {children}
    </InputWrapperView>
  );
}

export default InputWrapper;
