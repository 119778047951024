import SearchLogsPage from "../../../pages/searchLogs/index/SearchLogsPage";
import { RouteConfig } from "../../RouteConfig";
import useSearchLogsRouteValidator from "../../validators/pages/SearchLogsRouteValidator";

function useSearchLogsRouteConfig() {
  return new RouteConfig()
    .setPath("/dashboard/search_logs")
    .setLabel("Zoekgeschiedenis")
    .setValidator(useSearchLogsRouteValidator())
    .setPage(<SearchLogsPage />);
}

export default useSearchLogsRouteConfig;
