import { StyledError } from "../../styles/ErrorStyles";
import { StyledInputWrapper, StyledRequired } from "./InputWrapperStyles";

function InputWrapperView({ label, required, error, children, style }) {
  return (
    <StyledInputWrapper style={style}>
      <label>
        {label} {required && <StyledRequired>*</StyledRequired>}
      </label>

      {children}

      <StyledError dangerouslySetInnerHTML={{ __html: error }} />
    </StyledInputWrapper>
  );
}

export default InputWrapperView;
