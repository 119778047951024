import styled from "styled-components";

export const StyledSelect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  background-color: ${(props) => props.theme.colors.primary.lightest};
  border-radius: ${(props) => props.theme.radius.normal};
  padding: ${(props) => props.theme.spacing.normal};
  border: 1px solid transparent;
  color: ${(props) => props.theme.colors.on.primary};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  &:hover {
    border-color: ${(props) =>
      props.disabled ? "transparent" : props.theme.colors.accent.dark};
  }

  &:focus-within {
    border-color: ${(props) =>
      props.disabled ? "transparent" : props.theme.colors.accent.normal};
  }
`;

export const StyledValue = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledPlaceholder = styled.p`
  color: ${(props) => props.theme.colors.on.primary};
  opacity: 0.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledInput = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;

  &:disabled {
    cursor: initial;
  }
`;
