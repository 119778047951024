class LanguageHelper {
  static translatePlaceStatus(status) {
    const translations = {
      active: "Actief",
      capacity: "Capaciteit",
      partner: "Partner",
      not_interested: "Niet geïnteresseerd",
    };

    return translations[status] || status;
  }

  static translatePlaceType(type) {
    const translations = {
      bed_and_breakfast: "Bed and breakfast",
      campground: "Camping",
      hostel: "Hostel",
      hotel: "Hotel",
      motel: "Motel",
      real_estate: "Vastgoed",
    };

    return translations[type] || type;
  }

  static translateRole(role) {
    const translations = {
      admin: "Beheerder",
      user: "Gebruiker",
    };

    return translations[role] || role;
  }
}

export default LanguageHelper;
