import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { AddressHelper } from "../../../../helpers/AddressHelper";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import PlaceHelper from "../../../../helpers/PlaceHelper";
import TimeHelper from "../../../../helpers/TimeHelper";

function usePlacesPageFilters(placesData, fetchPlaces, setPlacesData) {
  const { state } = useLocation();
  const shouldFetchRef = useRef(false);
  const [filters, setFilters] = useState(
    state?.placesPage?.filters ?? {
      text_search: "",
      search_radius: 5000,
      order_by: "created_at",
      order_direction: "desc",
      statuses: PlaceHelper.getStatuses(),
      types: PlaceHelper.getTypes(),
      use_created_at_from: false,
      created_at_from: "",
      use_created_at_until: false,
      created_at_until: "",
      use_available_from: false,
      available_from: "",
      use_available_until: false,
      available_until: "",
      use_available_room_count: false,
      available_room_count: 0,
      use_available_person_count: false,
      available_person_count: 0,
    }
  );
  const filteredPlaces = getFilteredPlaces();

  /**
   * UseEffect for telling the parent component that the filters have changed
   */
  useCustomEffect(() => {
    if (shouldFetchRef.current) {
      fetchPlaces();
      shouldFetchRef.current = false;
    }
  }, [filters]);

  /**
   * This method will handle the text search change
   * @param {Event} event
   */
  function onTextSearchChange(event) {
    if (filters.text_search !== event.target.value) {
      setFilters((current) => ({
        ...current,
        text_search: event.target.value,
      }));
    }

    if (event.target.value.length === 0) {
      shouldFetchRef.current =
        filteredPlaces.filter((place) => place.status === "unknown").length > 0;
    }
  }

  /**
   * This method will handle the search radius change
   * @param {any} value
   */
  function onSearchRadiusChange(value) {
    if (filters.search_radius !== value) {
      if (filters.text_search.length !== 0) {
        shouldFetchRef.current = true;
      }

      setFilters((current) => ({
        ...current,
        search_radius: value,
      }));
    }
  }

  /**
   * This method will handle the order by change
   * @param {string} value
   */
  function onOrderByChange(value) {
    if (filters.order_by !== value) {
      setFilters((current) => ({
        ...current,
        order_by: value,
      }));
    }
  }

  /**
   * This method will handle the order direction change
   */
  function onOrderDirectionChange() {
    setFilters((current) => ({
      ...current,
      order_direction: current.order_direction === "asc" ? "desc" : "asc",
    }));
  }

  /**
   * This method will handle the types change
   * @param {array} value
   */
  function onTypesChange(value) {
    if (!value.includes(placesData.previewPlace?.type)) {
      setPlacesData((current) => ({
        ...current,
        previewPlace: null,
      }));
    }

    setFilters((current) => ({
      ...current,
      types: value,
    }));
  }

  /**
   * This method will handle the statuses change
   * @param {array} value
   */
  function onStatusesChange(value) {
    if (!value.includes(placesData.previewPlace?.status)) {
      setPlacesData((current) => ({
        ...current,
        previewPlace: null,
      }));
    }

    setFilters((current) => ({
      ...current,
      statuses: value,
    }));
  }

  /**
   * This method will handle the use created at from change
   * @param {boolean} value
   */
  function onUseCreatedAtFromChange(value) {
    setFilters((current) => ({
      ...current,
      use_created_at_from: value,
    }));
  }

  /**
   * This method will handle the created at from change
   * @param {Event} event
   */
  function onCreatedAtFromChange(event) {
    setFilters((current) => ({
      ...current,
      created_at_from: event.target.value,
    }));
  }

  /**
   * This method will handle the use created at until change
   * @param {Boolean} value
   */
  function onUseCreatedAtUntilChange(value) {
    setFilters((current) => ({
      ...current,
      use_created_at_until: value,
    }));
  }

  /**
   * This method will handle the created at until change
   * @param {Event} event
   */
  function onCreatedAtUntilChange(event) {
    setFilters((current) => ({
      ...current,
      created_at_until: event.target.value,
    }));
  }

  /**
   * This method will handle the use available from change
   * @param {boolean} value
   */
  function onUseAvailableFromChange(value) {
    setFilters((current) => ({
      ...current,
      use_available_from: value,
    }));
  }

  /**
   * This method will handle the available from change
   * @param {Event} event
   */
  function onAvailableFromChange(event) {
    setFilters((current) => ({
      ...current,
      available_from: event.target.value,
    }));
  }

  /**
   * This method will handle the use available until change
   * @param {boolean} value
   */
  function onUseAvailableUntilChange(value) {
    setFilters((current) => ({
      ...current,
      use_available_until: value,
    }));
  }

  /**
   * This method will handle the available until change
   * @param {Event} event
   */
  function onAvailableUntilChange(event) {
    setFilters((current) => ({
      ...current,
      available_until: event.target.value,
    }));
  }

  /**
   * This method will handle the use available room count change
   * @param {boolean} value
   */
  function onUseAvailableRoomCountChange(value) {
    setFilters((current) => ({
      ...current,
      use_available_room_count: value,
    }));
  }

  /**
   * This method will handle the available room count change
   * @param {Event} event
   */
  function onAvailableRoomCountChange(event) {
    setFilters((current) => ({
      ...current,
      available_room_count: event.target.value,
    }));
  }

  /**
   * This method will handle the use available person count change
   * @param {boolean} value
   */
  function onUseAvailablePersonCountChange(value) {
    setFilters((current) => ({
      ...current,
      use_available_person_count: value,
    }));
  }

  /**
   * This method will handle the available person count change
   * @param {Event} event
   */
  function onAvailablePersonCountChange(event) {
    setFilters((current) => ({
      ...current,
      available_person_count: event.target.value,
    }));
  }

  /**
   * This method will return the filtered places
   * @returns {array}
   */
  function getFilteredPlaces() {
    return placesData.places
      .filter((place) => {
        //Filter by status
        if (!filters.statuses.includes(place.status)) {
          if (place.status !== "unknown") {
            return false;
          }
        }

        //Filter by type
        if (!filters.types.includes(place.type)) {
          return false;
        }

        //Filter by address and name
        const placeUserName = place.user?.name ?? place.user_name ?? "";
        if (
          !AddressHelper.getFullAddress(place, ["country"])
            .toLowerCase()
            .includes(filters.text_search.toLowerCase()) &&
          !place.name
            .toLowerCase()
            .includes(filters.text_search.toLowerCase()) &&
          !placeUserName
            .toLowerCase()
            .includes(filters.text_search.toLowerCase())
        ) {
          if (place.status !== "unknown") {
            return false;
          }
        }

        //Filter by created at from
        if (
          filters.use_created_at_from &&
          filters.created_at_from &&
          TimeHelper.getDate(place.created_at) <
            TimeHelper.getDate(filters.created_at_from)
        ) {
          return false;
        }

        //Filter by created at until
        if (
          filters.use_created_at_until &&
          filters.created_at_until &&
          TimeHelper.getDate(place.created_at) >
            TimeHelper.getDate(filters.created_at_until)
        ) {
          return false;
        }

        //Filter by available from
        if (
          filters.use_available_from &&
          filters.available_from &&
          (new Date(place.available_from) > new Date(filters.available_from) ||
            new Date(place.available_until) < new Date(filters.available_from))
        ) {
          return false;
        }

        //Filter by available until
        if (
          filters.use_available_until &&
          filters.available_until &&
          (new Date(place.available_until) <
            new Date(filters.available_until) ||
            new Date(place.available_from) > new Date(filters.available_until))
        ) {
          return false;
        }

        //Filter by available room count
        if (
          filters.use_available_room_count &&
          filters.available_room_count &&
          place.available_room_count - place.booked_room_count <
            filters.available_room_count
        ) {
          return false;
        }

        //Filter by available person count
        if (
          filters.use_available_person_count &&
          filters.available_person_count &&
          place.available_person_count - place.booked_person_count <
            filters.available_person_count
        ) {
          return false;
        }

        return true;
      })
      .sort(sortPlaces);
  }

  /**
   * This method will sort the places
   * @param {object} a
   * @param {object} b
   * @returns {number}
   */
  function sortPlaces(a, b) {
    if (filters.order_by === "name") {
      if (filters.order_direction === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    }

    if (filters.order_by === "created_at") {
      if (filters.order_direction === "asc") {
        return (
          TimeHelper.getDate(a.created_at) - TimeHelper.getDate(b.created_at)
        );
      } else {
        return (
          TimeHelper.getDate(b.created_at) - TimeHelper.getDate(a.created_at)
        );
      }
    }
  }

  return {
    fetchPlaces,
    filters,
    filteredPlaces,
    onTextSearchChange,
    onOrderByChange,
    onOrderDirectionChange,
    onSearchRadiusChange,
    onTypesChange,
    onStatusesChange,
    onUseCreatedAtFromChange,
    onCreatedAtFromChange,
    onUseCreatedAtUntilChange,
    onCreatedAtUntilChange,
    onUseAvailableFromChange,
    onAvailableFromChange,
    onUseAvailableUntilChange,
    onAvailableUntilChange,
    onUseAvailableRoomCountChange,
    onAvailableRoomCountChange,
    onUseAvailablePersonCountChange,
    onAvailablePersonCountChange,
  };
}

export default usePlacesPageFilters;
