import UserPage from "../../../../pages/users/user/UserPage";
import { RouteConfig } from "../../../RouteConfig";
import useUsersRouteConfig from "./UsersRouteConfig";
import useUserEditRouteValidator from "../../../validators/pages/users/UserEditRouteValidator";

function useUserEditRouteConfig() {
  return new RouteConfig()
    .setPath("/dashboard/users/:user/edit")
    .setLabel("Gebruiker bewerken")
    .setValidator(useUserEditRouteValidator())
    .setPage(<UserPage />)
    .setParent(useUsersRouteConfig());
}

export default useUserEditRouteConfig;
