import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { StyledOverlay, StyledOverlayLoader } from "./OverlayLoaderStyles";
import { useLoadContext } from "../../../contexts/LoadContext";

function OverlayLoaderView({
  groups,
  children,
  style,
  size = "lg",
  rounded = false,
}) {
  const loadContext = useLoadContext();
  const isLoading = loadContext.isLoading(groups);

  return (
    <StyledOverlayLoader style={style}>
      {children}

      {isLoading && (
        <StyledOverlay rounded={rounded.toString()}>
          <FontAwesomeIcon icon={faSpinner} spin size={size} />
        </StyledOverlay>
      )}
    </StyledOverlayLoader>
  );
}

export default OverlayLoaderView;
