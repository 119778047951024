import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlacePreview from "../../../../components/placePreview/PlacePreview";
import {
  StyledListLayout,
  StyledNoResults,
  StyledPlacesPageList,
} from "./PlacesPageListStyles";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import HiddenLoader from "../../../../components/loader/hidden/HiddenLoader";
import { useAuthContext } from "../../../../contexts/AuthContext";

function PlacesPageListView({
  placesData,
  filtersController,
  setPlacesData,
  fetchPlaces,
  onExport,
}) {
  const authContext = useAuthContext();

  return (
    <StyledPlacesPageList>
      <StyledListLayout>
        {filtersController.filteredPlaces.length === 0 && (
          <StyledNoResults>
            <FontAwesomeIcon icon={faInfoCircle} />
            Er zijn geen plaatsen gevonden met de huidige zoekcriteria. Pas de
            filters aan om meer resultaten te zien of voeg nieuwe plaatsen toe
            via de kaart.
          </StyledNoResults>
        )}

        {filtersController.filteredPlaces.length > 0 &&
          authContext.auth.user?.role === "admin" && (
            <HiddenLoader
              groups={"places_page_export"}
              style={{ marginLeft: "auto" }}
            >
              <button onClick={onExport}>Export</button>
            </HiddenLoader>
          )}

        {filtersController.filteredPlaces.map((place) => {
          return (
            place.status !== "unknown" && (
              <PlacePreview
                key={place.id}
                place={place}
                placesData={placesData}
                filtersController={filtersController}
                setPlacesData={setPlacesData}
                fetchPlaces={fetchPlaces}
              />
            )
          );
        })}
      </StyledListLayout>
    </StyledPlacesPageList>
  );
}

export default PlacesPageListView;
