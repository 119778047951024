import React, { useContext, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const GoogleMapsContext = React.createContext();

export function GoogleMapsProvider({ children }) {
  const [initialized, setInitialized] = useState(false);
  const [libraries, setLibraries] = useState({
    maps: null,
    markers: null,
  });

  /**
   * Initialize the Google Maps API
   */
  async function initialize() {
    //Create loader
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
    });

    //Import libraries
    const maps = await loader.importLibrary("maps");
    const markers = await loader.importLibrary("marker");

    //Update the states
    setLibraries({
      maps: maps,
      markers: markers,
    });
    setInitialized(true);
  }

  return (
    <GoogleMapsContext.Provider value={{ initialized, libraries, initialize }}>
      {children}
    </GoogleMapsContext.Provider>
  );
}

export function useGoogleMapsContext() {
  return useContext(GoogleMapsContext);
}
