import api from "../../../../apis/api";
import { useLoadContext } from "../../../../contexts/LoadContext";
import FileHelper from "../../../../helpers/FileHelper";
import PlacesPageListView from "./PlacesPageListView";

function PlacesPageList({
  placesData,
  filtersController,
  setPlacesData,
  fetchPlaces,
}) {
  const loadContext = useLoadContext();

  /**
   * This method will export the places data
   */
  async function onExport() {
    const loadTag = loadContext.enableLoader("places_page_export");

    try {
      const response = await api.get("places/export", {
        responseType: "blob",
        params: filtersController.filters,
      });
      if (response.status === 200) {
        FileHelper.download(response.data, "places");
      }
    } catch (error) {
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  return (
    <PlacesPageListView
      placesData={placesData}
      filtersController={filtersController}
      setPlacesData={setPlacesData}
      fetchPlaces={fetchPlaces}
      onExport={onExport}
    />
  );
}

export default PlacesPageList;
