import OverlayLoaderView from "./OverlayLoaderView";

function OverlayLoader({ groups, children, style, size, rounded }) {
  return (
    <OverlayLoaderView
      groups={groups}
      style={style}
      size={size}
      rounded={rounded}
    >
      {children}
    </OverlayLoaderView>
  );
}

export default OverlayLoader;
