import {
  StyledBody,
  StyledPlacePreviewLayout,
  StyledPlacePreviewWrapper,
  StyledPlacesPage,
} from "./PlacesPageStyles";
import OverlayLoader from "../../../components/loader/overlay/OverlayLoader";
import PlacePreview from "../../../components/placePreview/PlacePreview";
import PlacesPageMap from "./map/PlacesPageMap";
import PlacesPageList from "./list/PlacesPageList";
import PlacesPageFilters from "./filters/PlacesPageFilters";
import PlacesPageSearchBar from "./search/PlacesPageSearchBar";

function PlacesPageView({
  googleMaps,
  placesData,
  setPlacesData,
  fetchPlaces,
  filtersController,
}) {
  return (
    <StyledPlacesPage>
      <PlacesPageSearchBar
        placesData={placesData}
        filtersController={filtersController}
        setPlacesData={setPlacesData}
      />

      <OverlayLoader
        groups={"places_page_fetch"}
        size={"2xl"}
        style={{ height: "100%" }}
      >
        <StyledBody>
          <PlacesPageMap
            googleMaps={googleMaps}
            placesData={placesData}
            setPlacesData={setPlacesData}
            filtersController={filtersController}
          />

          {placesData.previewPlace && (
            <StyledPlacePreviewWrapper>
              <StyledPlacePreviewLayout>
                <PlacePreview
                  place={placesData.previewPlace}
                  enableClose={true}
                  placesData={placesData}
                  filtersController={filtersController}
                  setPlacesData={setPlacesData}
                  fetchPlaces={fetchPlaces}
                />
              </StyledPlacePreviewLayout>
            </StyledPlacePreviewWrapper>
          )}

          {!placesData.showMap && (
            <PlacesPageList
              placesData={placesData}
              filtersController={filtersController}
              setPlacesData={setPlacesData}
              fetchPlaces={fetchPlaces}
            />
          )}

          {placesData.showFilters && (
            <PlacesPageFilters
              filtersController={filtersController}
              placesData={placesData}
              setPlacesData={setPlacesData}
            />
          )}
        </StyledBody>
      </OverlayLoader>
    </StyledPlacesPage>
  );
}

export default PlacesPageView;
