import DashboardLayoutView from "./DashboardLayoutView";
import { useState } from "react";

function DashboardLayout() {
  const [dashboardData, setDashboardData] = useState({
    header: "",
    previousUrl: null,
  });

  /**
   * This method will update the dashboard data
   * @param {object} data
   */
  function updateDashboardData(data) {
    setDashboardData((current) => ({
      ...dashboardData,
      header: data.header,
      previousUrl: data.previousUrl,
    }));
  }

  return (
    <DashboardLayoutView
      dashboardData={dashboardData}
      updateDashboardData={updateDashboardData}
    />
  );
}

export default DashboardLayout;
