import styled from "styled-components";

export const StyledPlacesPageList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary.dark};
  overflow-y: auto;
`;

export const StyledListLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  width: 100%;
  max-width: ${(props) => props.theme.layout.maxWidth};
  padding: ${(props) => props.theme.spacing.large};
  gap: ${(props) => props.theme.spacing.large};
`;

export const StyledNoResults = styled.p`
  border: 1px solid ${(props) => props.theme.colors.accent.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  padding: ${(props) => props.theme.spacing.normal};
  background-color: ${(props) =>
    `rgba(${props.theme.colors.accent.normalRGB}, 0.1)`};
  color: ${(props) => props.theme.colors.accent.normal};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
`;
