import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledAltIconButton } from "../../../../styles/InputStyles";
import {
  StyledEnableFilterWrapper,
  StyledHeader,
  StyledLayout,
  StyledPlacesPageFilters,
} from "./PlacesPageFiltersStyles";
import {
  faArrowDown,
  faArrowUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import InputWrapper from "../../../../components/inputWrapper/InputWrapper";
import Select from "../../../../components/select/main/Select";
import MultiSelect from "../../../../components/select/main/multiSelect/MultiSelect";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import PlaceHelper from "../../../../helpers/PlaceHelper";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { StyledFlexItem, StyledFlexRow } from "../../../../styles/LayoutStyles";

function PlacesPageFiltersView({ filtersController, onCloseClick }) {
  return (
    <StyledPlacesPageFilters>
      <StyledHeader>
        <h3>Filters</h3>

        <StyledAltIconButton onClick={onCloseClick}>
          <FontAwesomeIcon icon={faXmark} />
        </StyledAltIconButton>
      </StyledHeader>

      <StyledLayout>
        <InputWrapper label="Zoek radius">
          <Select
            value={filtersController.filters.search_radius}
            onChange={filtersController.onSearchRadiusChange}
            options={[
              { value: 1000, label: "1 km" },
              { value: 2500, label: "2.5 km" },
              { value: 5000, label: "5 km" },
              { value: 10000, label: "10 km" },
            ]}
          />
        </InputWrapper>

        <InputWrapper label="Statussen">
          <MultiSelect
            values={filtersController.filters.statuses}
            onChange={filtersController.onStatusesChange}
            options={PlaceHelper.getStatuses().map((status) => ({
              value: status,
              label: LanguageHelper.translatePlaceStatus(status),
            }))}
          />
        </InputWrapper>

        <InputWrapper label="Types">
          <MultiSelect
            values={filtersController.filters.types}
            onChange={filtersController.onTypesChange}
            options={PlaceHelper.getTypes().map((type) => ({
              value: type,
              label: LanguageHelper.translatePlaceType(type),
            }))}
          />
        </InputWrapper>

        <InputWrapper label={"Sorteren"}>
          <StyledFlexRow>
            <StyledFlexItem>
              <Select
                value={filtersController.filters.order_by}
                onChange={filtersController.onOrderByChange}
                options={[
                  { value: "name", label: "Naam" },
                  { value: "created_at", label: "Aangemaakt" },
                ]}
              />
            </StyledFlexItem>

            <StyledAltIconButton
              onClick={filtersController.onOrderDirectionChange}
            >
              <FontAwesomeIcon
                icon={
                  filtersController.filters.order_direction === "asc"
                    ? faArrowUp
                    : faArrowDown
                }
              />
            </StyledAltIconButton>
          </StyledFlexRow>
        </InputWrapper>

        <InputWrapper label={"Aangemaakt vanaf"}>
          <StyledEnableFilterWrapper>
            <Checkbox
              value={filtersController.filters.use_created_at_from}
              onChange={filtersController.onUseCreatedAtFromChange}
            />

            <input
              disabled={!filtersController.filters.use_created_at_from}
              type="date"
              value={filtersController.filters.created_at_from}
              onChange={filtersController.onCreatedAtFromChange}
            />
          </StyledEnableFilterWrapper>
        </InputWrapper>

        <InputWrapper label="Aangemaakt t/m">
          <StyledEnableFilterWrapper>
            <Checkbox
              value={filtersController.filters.use_created_at_until}
              onChange={filtersController.onUseCreatedAtUntilChange}
            />

            <input
              disabled={!filtersController.filters.use_created_at_until}
              type="date"
              value={filtersController.filters.created_at_until}
              onChange={filtersController.onCreatedAtUntilChange}
            />
          </StyledEnableFilterWrapper>
        </InputWrapper>

        <InputWrapper label="Beschikbaar vanaf">
          <StyledEnableFilterWrapper>
            <Checkbox
              value={filtersController.filters.use_available_from}
              onChange={filtersController.onUseAvailableFromChange}
            />

            <input
              disabled={!filtersController.filters.use_available_from}
              type="date"
              value={filtersController.filters.available_from}
              onChange={filtersController.onAvailableFromChange}
            />
          </StyledEnableFilterWrapper>
        </InputWrapper>

        <InputWrapper label="Beschikbaar t/m">
          <StyledEnableFilterWrapper>
            <Checkbox
              value={filtersController.filters.use_available_until}
              onChange={filtersController.onUseAvailableUntilChange}
            />

            <input
              disabled={!filtersController.filters.use_available_until}
              type="date"
              value={filtersController.filters.available_until}
              onChange={filtersController.onAvailableUntilChange}
            />
          </StyledEnableFilterWrapper>
        </InputWrapper>

        <InputWrapper label="Kamers beschikbaar">
          <StyledEnableFilterWrapper>
            <Checkbox
              value={filtersController.filters.use_available_room_count}
              onChange={filtersController.onUseAvailableRoomCountChange}
            />

            <input
              disabled={!filtersController.filters.use_available_room_count}
              type="number"
              value={filtersController.filters.available_room_count}
              onChange={filtersController.onAvailableRoomCountChange}
            />
          </StyledEnableFilterWrapper>
        </InputWrapper>

        <InputWrapper label="Personen beschikbaar">
          <StyledEnableFilterWrapper>
            <Checkbox
              value={filtersController.filters.use_available_person_count}
              onChange={filtersController.onUseAvailablePersonCountChange}
            />

            <input
              disabled={!filtersController.filters.use_available_person_count}
              type="number"
              value={filtersController.filters.available_person_count}
              onChange={filtersController.onAvailablePersonCountChange}
            />
          </StyledEnableFilterWrapper>
        </InputWrapper>
      </StyledLayout>
    </StyledPlacesPageFilters>
  );
}

export default PlacesPageFiltersView;
