import { createGlobalStyle } from "styled-components";

const InputStyles = createGlobalStyle`
  input, textarea {
    background-color: ${(props) => props.theme.colors.primary.lightest};
    border-radius: ${(props) => props.theme.radius.normal};
    border: 1px solid transparent;
    padding: ${(props) => props.theme.spacing.normal};

    &:hover {
      border-color: ${(props) => props.theme.colors.accent.dark};
    }

    &:focus {
      outline: none;
      border-color: ${(props) => props.theme.colors.accent.normal};
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.on.primary};
      opacity: 0.3;
    }

    &:disabled {
      opacity: 0.33;
      pointer-events: none;
    }
  }

  button {
    background-color: ${(props) => props.theme.colors.accent.normal};
    border-radius: ${(props) => props.theme.radius.normal};
    padding: ${(props) => props.theme.spacing.normal};
    font-weight: bold;

    &:hover {
      background-color: ${(props) => props.theme.colors.accent.dark};
      color: ${(props) => props.theme.colors.on.accent};
    }

    &:disabled {
      opacity: 0.33;
      pointer-events: none;
    }
  }

  select {
    padding-inline: ${(props) => props.theme.spacing.normal};
  }

  option {
    background-color: ${(props) => props.theme.colors.primary.lightest};
    color: ${(props) => props.theme.colors.on.primary};

    &:checked {
      background-color: ${(props) => props.theme.colors.primary.normal};
      color: ${(props) => props.theme.colors.on.primary};
    }
  }
`;

export default InputStyles;
