import styled from "styled-components";
import { StyledTextButton } from "../../styles/InputStyles";

export const StyledInteractiveInput = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small};
  background-color: ${(props) => props.theme.colors.primary.lightest};
  border-radius: ${(props) => props.theme.radius.normal};
  border: 1px solid transparent;
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};

  &:hover {
    border-color: ${(props) => props.theme.colors.accent.dark};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.accent.normal};
  }

  & > * {
    pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  }
`;

export const StyledInput = styled.input`
  flex: 1;
  border: none !important;
`;

export const StyledClearButton = styled(StyledTextButton)`
  margin-right: ${(props) => props.theme.spacing.normal};
`;
