import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import LayoutRouteConfig from "../../LayoutRouteConfig";
import useDashboardLayoutRouteValidator from "../../validators/layouts/DashboardLayoutRouteValidator";

function useDashboardLayoutRouteConfig() {
  return new LayoutRouteConfig()
    .setLayout(<DashboardLayout />)
    .setValidator(useDashboardLayoutRouteValidator());
}

export default useDashboardLayoutRouteConfig;
