import React from "react";
import { useAuthContext } from "./AuthContext";
import useCustomEffect from "../hooks/useCustomEffect";
import { useGoogleMapsContext } from "./GoogleMapsContext";

export function ContextHandler({ children }) {
  const authContext = useAuthContext();
  const googleMapsContext = useGoogleMapsContext();

  /**
   * Fetch the user
   */
  useCustomEffect(() => {
    authContext.fetchUser();
    googleMapsContext.initialize();
  });

  return (
    <>
      {!authContext.fetchingUser && googleMapsContext.initialized && children}
    </>
  );
}
