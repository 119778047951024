import styled from "styled-components";
import { StyledIconButton } from "../../styles/InputStyles";

export const StyledCheckbox = styled(StyledIconButton)`
  background-color: ${(props) =>
    `rgba(${props.theme.colors.primary.lightestRGB}, 0.33)`};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  color: ${(props) => props.theme.colors.on.primary};

  &:hover {
    background-color: ${(props) =>
      `rgba(${props.theme.colors.primary.lightestRGB}, 0.33)`};
    border-color: ${(props) => props.theme.colors.accent.dark};
    color: ${(props) => props.theme.colors.accent.dark};
  }
`;
