import styled from "styled-components";

export const StyledMapInfo = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing.normal};
  right: ${(props) => props.theme.spacing.large};
  background-color: rgba(0, 0, 0, 0.5);
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
`;
