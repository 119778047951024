import styled from "styled-components";

export const StyledSearchBar = styled.div`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledSearchBarLayout = styled.div`
  margin-inline: auto;
  max-width: ${(props) => props.theme.layout.maxWidth};
  padding: ${(props) => props.theme.spacing.normal};
  padding-inline: ${(props) => props.theme.spacing.large};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  justify-content: flex-end;
`;
