import axios from "axios";
import { Cookies } from "react-cookie";

/**
 * The default axios configuration for fetching the EVA API
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/`,
});

/**
 * The request interceptor which will automatically set the authorization token
 */
export const AuthorizedRequestInterceptor = api.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"]) {
      const token = new Cookies().get("jwt_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * This request interceptor will set the timezone for each API request
 */
export const TimezoneRequestInterceptor = api.interceptors.request.use(
  (config) => {
    if (!config.headers["Timezone"]) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers["Timezone"] = timezone;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * The response interceptor which will automatically logout the current user when the API returns a unautorized response
 */
export const UnauthorizedResponseInterceptor = api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      if (window.location.pathname !== "/login") {
        window.location = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default api;
