import styled from "styled-components";

export const StyledPlacesPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledHeader = styled.div`
  background-color: ${(props) => props.theme.colors.primary.darkest};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
`;

export const StyledHeaderLayout = styled.div`
  margin-inline: auto;
  max-width: ${(props) => props.theme.layout.maxWidth};
  padding: ${(props) => props.theme.spacing.normal};
  padding-inline: ${(props) => props.theme.spacing.large};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
`;

export const StyledSearchInput = styled.input`
  flex: 1;
`;

export const StyledBody = styled.div`
  position: relative;
  height: 100%;
`;

export const StyledFiltersContainer = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing.normal};
  left: ${(props) => props.theme.spacing.large};
  background-color: ${(props) => props.theme.colors.primary.darkest};
  padding: ${(props) => props.theme.spacing.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  box-shadow: 10px 10px 30px -10px rgba(0, 0, 0, 0.3);
`;

export const StyledFiltersHeader = styled.p`
  font-weight: bold;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  padding-bottom: ${(props) => props.theme.spacing.normal};
  margin-bottom: ${(props) => props.theme.spacing.normal};
`;

export const StyledPlacePreviewWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => props.theme.spacing.large};
  left: 0;
  width: 100%;
`;

export const StyledPlacePreviewLayout = styled.div`
  margin-inline: auto;
  max-width: ${(props) => props.theme.layout.maxWidth};
  width: 100%;
  padding-inline: ${(props) => props.theme.spacing.large};
`;
