import api from "../apis/api";

class FileHelper {
  /**
   * This method will simulate a file select
   * @param {string} accept
   * @returns {Promise<File>}
   */
  static select(accept, multiple = false) {
    return new Promise((resolve, reject) => {
      //Create an input element
      const input = document.createElement("input");
      input.type = "file";
      input.style.display = "none";
      input.accept = accept;
      input.multiple = multiple;

      //Add a change event listener to the input
      input.addEventListener("change", (event) => {
        const files = multiple
          ? Array.from(event.target.files)
          : event.target.files[0];
        resolve(files);
      });

      //Add a cancel event listener to the input
      input.addEventListener("cancel", () => {
        resolve(null);
      });

      //Simulate a click on the input
      document.body.appendChild(input);
      input.click();
      input.remove();
    });
  }

  /**
   * This method will get the extension of the file
   * @param {string} path
   * @returns {string}
   */
  static extension(path) {
    return path.split(".").pop();
  }

  /**
   * This method will return the api file path based on the given path
   * @param {string} path
   * @returns {Promise<string>}
   */
  static async apiFilePath(path) {
    if (path.includes("public/")) {
      path = path.replace("public/", "");
      return `${process.env.REACT_APP_API_HOST}/storage/${path}`;
    } else {
      try {
        const response = await api.get(`files/${path}`, {
          responseType: "blob",
        });
        if (response.status === 200 && response.data instanceof Blob) {
          return URL.createObjectURL(response.data);
        } else {
          return path;
        }
      } catch (error) {
        return path;
      }
    }
  }

  /**
   * This method will download the given file
   * @param {Blob} file
   * @param {string} filename
   */
  static download(file, filename) {
    if (file instanceof Blob === false) {
      return;
    }

    const link = document.createElement("a");
    link.setAttribute("download", filename);
    link.href = URL.createObjectURL(file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default FileHelper;
