import { usePlaceInfoRequestContext } from "../../contexts/PlaceInfoRequestContext";
import PlaceInfoRequestsButtonView from "./PlaceInfoRequestsButtonView";
import { useAuthContext } from "../../contexts/AuthContext";
import { useState } from "react";

function PlaceInfoRequestsButton() {
  const authContext = useAuthContext();
  const { data, deletePlaceInfoRequest } = usePlaceInfoRequestContext();
  const [open, setOpen] = useState(false);

  /**
   * This method will handle the button click
   */
  function onButtonClick() {
    setOpen((current) => !current);
  }

  /**
   * This method will handle the phone click
   * @param {object} request
   */
  function onPhoneClick(request) {
    if ("href" in document.createElement("a")) {
      const link = document.createElement("a");
      link.href = `tel:${request.user.phone_number}`;
      link.click();
      link.remove();
    }
  }

  /**
   * This method will handle the mail click
   * @param {object} request
   */
  function onMailClick(request) {
    if ("href" in document.createElement("a")) {
      const link = document.createElement("a");
      link.href = `mailto:${request.user.email}`;
      link.click();
      link.remove();
    }
  }

  /**
   * This method will handle the delete click
   * @param {object} request
   */
  async function onDeleteClick(request) {
    await deletePlaceInfoRequest(request.id);
    if (data.requests.length <= 1) {
      setOpen(false);
    }
  }

  return (
    authContext.auth.user?.role === "admin" && (
      <PlaceInfoRequestsButtonView
        data={data}
        open={open}
        onButtonClick={onButtonClick}
        onPhoneClick={onPhoneClick}
        onMailClick={onMailClick}
        onDeleteClick={onDeleteClick}
      />
    )
  );
}

export default PlaceInfoRequestsButton;
