import { useRef } from "react";
import { useGoogleMapsContext } from "../../contexts/GoogleMapsContext";
import useCustomEffect from "../../hooks/useCustomEffect";

function GoogleMapMarker({ controller, position, title, color, onClick }) {
  const { libraries } = useGoogleMapsContext();
  const markerRef = useRef(null);

  /**
   * UseEffect for when the component is unmounted
   */
  useCustomEffect(() => {
    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
    };
  }, []);

  /**
   * UseEffect to update the map marker
   */
  useCustomEffect(() => {
    //Check if the map is loaded and the position is set
    if (!controller.map || !position) return null;

    //Clear old marker
    if (markerRef.current) {
      markerRef.current.setMap(null);
    }

    //Create pin
    const pin = new libraries.markers.PinElement({
      background: color,
      borderColor: "black",
      glyphColor: "black",
    });

    //Create new marker
    markerRef.current = new libraries.markers.AdvancedMarkerElement({
      position: position,
      map: controller.map,
      title: title,
      content: pin.element,
    });

    //Add the click event
    if (markerRef.current) {
      markerRef.current.addListener("click", onMarkerClick);
    }
  }, [controller.map, position.lat, position.lng, title, color]);

  /**
   * This method will handle the marker click event
   */
  function onMarkerClick() {
    if (onClick) {
      onClick();
    }
  }

  return "";
}

export default GoogleMapMarker;
