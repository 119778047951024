import { useLoadContext } from "../../../contexts/LoadContext";
import HiddenLoaderView from "./HiddenLoaderView";

function HiddenLoader({ groups, children, style }) {
  const loadContext = useLoadContext();
  return (
    <HiddenLoaderView loading={loadContext.isLoading(groups)} style={style}>
      {children}
    </HiddenLoaderView>
  );
}

export default HiddenLoader;
