import { useNavigate } from "react-router-dom";
import api from "../../apis/api";
import { useLoadContext } from "../../contexts/LoadContext";
import PlacePreviewView from "./PlacePreviewView";
import { ApiHelper } from "../../helpers/ApiHelper";
import { usePlaceInfoRequestContext } from "../../contexts/PlaceInfoRequestContext";

function PlacePreview({
  place,
  placesData,
  filtersController,
  setPlacesData,
  fetchPlaces,
  enableClose = false,
}) {
  const navigate = useNavigate();
  const loadContext = useLoadContext();
  const placeInfoRequestContext = usePlaceInfoRequestContext();
  const requested = placeInfoRequestContext.data.requested.find(
    (request) => request.place.id === place.id
  );

  /**
   * This method will handle the add click
   */
  function onAddClick() {
    navigate(`/dashboard/places/create`, {
      state: {
        place: place,
        placesPage: {
          data: placesData,
          filters: filtersController.filters,
        },
      },
    });
  }

  /**
   * This method will handle the edit click
   */
  function onEditClick() {
    navigate(`/dashboard/places/${place.id}/edit`, {
      state: {
        placesPage: {
          data: placesData,
          filters: filtersController.filters,
        },
      },
    });
  }

  /**
   * This method will handle the delete click
   */
  async function onDeleteClick() {
    if (!window.confirm("Weet je zeker dat je deze plaats wilt verwijderen?")) {
      return;
    }

    const loadTag = loadContext.enableLoader(
      `place_preview_delete_${place.id}`
    );

    try {
      await api.delete(`places/${place.id}`);
    } catch (error) {
    } finally {
      loadContext.disableLoader(loadTag);
      setPlacesData((current) => ({
        ...current,
        previewPlace: null,
      }));
      fetchPlaces();
    }
  }

  /**
   * This method will handle the phone click
   */
  function onPhoneClick() {
    if ("href" in document.createElement("a")) {
      const link = document.createElement("a");
      link.href = `tel:${place.phone_number}`;
      link.click();
      link.remove();
    }
  }

  /**
   * This method will handle the location click
   */
  function handleOnLocationClick() {
    setPlacesData((current) => ({
      ...current,
      previewPlace: place,
      showMap: true,
    }));
  }

  /**
   *
   * @returns
   */
  async function onRequestInfoClick() {
    if (
      !window.confirm(
        requested
          ? "Weet je zeker dat je dit verzoek wilt annuleren?"
          : "Weet je zeker dat je dit verzoek wilt indienen?"
      )
    ) {
      return;
    }

    const loadTag = loadContext.enableLoader(
      `place_preview_delete_${place.id}`
    );

    try {
      if (!requested) {
        const response = await api.post(
          "place_info_requests",
          ApiHelper.objectToFormData({
            place_id: place.id,
          })
        );
        if (response.status === 200 && response.data?.success) {
          placeInfoRequestContext.fetchPlaceInfoRequests();
        }
      } else {
        await placeInfoRequestContext.deletePlaceInfoRequest(
          requested.id,
          false
        );
      }
    } catch (error) {
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will handle the close click
   */
  function onCloseClick() {
    setPlacesData((current) => ({
      ...current,
      previewPlace: null,
    }));
  }

  return (
    <PlacePreviewView
      requested={requested}
      place={place}
      enableClose={enableClose}
      onAddClick={onAddClick}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      onCloseClick={onCloseClick}
      onPhoneClick={onPhoneClick}
      onLocationClick={handleOnLocationClick}
      onRequestInfoClick={onRequestInfoClick}
    />
  );
}

export default PlacePreview;
