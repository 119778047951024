import { useTheme } from "styled-components";
import { StyledCard, StyledCardBody } from "../../../../styles/CardStyles";
import OverlayLoader from "../../../../components/loader/overlay/OverlayLoader";
import {
  StyledAltIconButton,
  StyledIconButton,
} from "../../../../styles/InputStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faLockOpen,
  faPlus,
  faTrash,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import {
  StyledFile,
  StyledFileInfo,
  StyledFileName,
  StyledHeader,
} from "./PlacePageFilesStyles";
import HiddenLoader from "../../../../components/loader/hidden/HiddenLoader";
import FilePreview from "../../../../components/FilePreview/FilePreview";
import FileHelper from "../../../../helpers/FileHelper";
import { StyledFlexRow } from "../../../../styles/LayoutStyles";
import { useAuthContext } from "../../../../contexts/AuthContext";

function PlacePageFilesView({
  files,
  previewFile,
  createFile,
  updateFilePrivacy,
  deleteFile,
  getFileIcon,
  onFileClick,
  onPreviewClose,
}) {
  const theme = useTheme();
  const authContext = useAuthContext();

  return (
    <>
      <OverlayLoader groups={"place_page_files_fetch"} rounded={true}>
        <StyledCard style={{ marginTop: theme.spacing.large }}>
          <StyledHeader>
            <h2>Bestanden</h2>

            {authContext.auth.user?.role === "admin" && (
              <HiddenLoader groups={"place_page_files_create"}>
                <StyledIconButton onClick={createFile}>
                  <FontAwesomeIcon icon={faPlus} />
                </StyledIconButton>
              </HiddenLoader>
            )}
          </StyledHeader>

          <StyledCardBody>
            {files.map((file) => (
              <StyledFile key={file.id}>
                <StyledFileInfo>
                  <FontAwesomeIcon icon={getFileIcon(file)} size="lg" />

                  <StyledFileName onClick={() => onFileClick(file)}>
                    {file.name}
                  </StyledFileName>
                </StyledFileInfo>

                {authContext.auth.user?.role === "admin" && (
                  <HiddenLoader groups={`place_page_files_delete_${file.id}`}>
                    <StyledFlexRow>
                      <StyledAltIconButton
                        onClick={() => {
                          updateFilePrivacy(file);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={file.private ? faLock : faLockOpen}
                        />
                      </StyledAltIconButton>
                      <StyledAltIconButton onClick={() => deleteFile(file)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </StyledAltIconButton>
                    </StyledFlexRow>
                  </HiddenLoader>
                )}
              </StyledFile>
            ))}
          </StyledCardBody>
        </StyledCard>
      </OverlayLoader>

      {previewFile && (
        <FilePreview
          path={previewFile.authenticatedPath}
          extension={FileHelper.extension(previewFile.path)}
          onClose={onPreviewClose}
        />
      )}
    </>
  );
}

export default PlacePageFilesView;
