import styled from "styled-components";
import { StyledAltIconButton } from "../../styles/InputStyles";
import PlaceHelper from "../../helpers/PlaceHelper";

export const StyledPlacePreview = styled.div`
  position: relative;
  width: 100%;
  max-width: ${(props) => props.theme.layout.maxWidth};
  background-color: ${(props) => props.theme.colors.primary.normal};
  border-radius: ${(props) => props.theme.radius.normal};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  padding: ${(props) => props.theme.spacing.normal};
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.normal};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lightest};
  padding-bottom: ${(props) => props.theme.spacing.normal};
  margin-bottom: ${(props) => props.theme.spacing.normal};
`;

export const StyledName = styled.h3`
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 500px) {
    max-width: 150px;
  }
`;

export const StyledHeaderTagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const StyledTag = styled.p`
  padding: ${(props) => props.theme.spacing.small};
  border-radius: ${(props) => props.theme.radius.small};
`;

export const StyledTags = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledType = styled(StyledTag)`
  background-color: ${(props) => props.theme.colors.primary.lightest};
  font-weight: bold;
`;

export const StyledStatus = styled(StyledTag)`
  background-color: ${(props) =>
    PlaceHelper.getStatusColor(props.status).background};
  color: ${(props) => PlaceHelper.getStatusColor(props.status).text};
  font-weight: bold;
`;

export const StyledActions = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.small};
  align-self: flex-start;
  flex-wrap: wrap;
`;

export const StyledRequestInfoButton = styled(StyledAltIconButton)`
  background-color: ${(props) =>
    props.requested
      ? props.theme.colors.accent.normal
      : props.theme.colors.primary.lightest};
  color: ${(props) =>
    props.requested
      ? props.theme.colors.on.accent
      : props.theme.colors.on.primary};
`;

export const StyledInteractableTr = styled.tr`
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.accent.dark};
    background-color: transparent;
  }
`;

export const StyledTd = styled.td`
  padding: ${(props) => props.theme.spacing.small};
  vertical-align: top;
`;

export const StyledCloseButton = styled(StyledAltIconButton)`
  position: absolute;
  top: ${(props) => `calc(-1 * (30px + ${props.theme.spacing.normal}))`};
  right: ${(props) => props.theme.spacing.normal};
`;
