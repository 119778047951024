import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../Select";
import {
  StyledAction,
  StyledActionsContainer,
  StyledMultiSelect,
  StyledPlaceholder,
  StyledValue,
  StyledValuesContainer,
} from "./MultiSelectStyles";
import { faCaretDown, faXmark } from "@fortawesome/free-solid-svg-icons";

function MultiSelectView({
  availableOptions,
  options,
  values,
  onSelectChange,
  onValueClick,
  onClear,
  disabled,
  title,
}) {
  return (
    <Select
      options={availableOptions}
      disabled={disabled}
      title={title}
      hideCaret={true}
      onChange={onSelectChange}
      valueVisual={(value) => (
        <StyledMultiSelect>
          <StyledValuesContainer>
            {values?.map((value) => (
              <StyledValue
                key={value}
                onClick={() => onValueClick(value)}
                disabled={disabled}
              >
                {
                  options?.find((option) => `${option.value}` === `${value}`)
                    ?.label
                }
              </StyledValue>
            ))}

            {(!values || values.length === 0) && (
              <StyledPlaceholder>Selecteer</StyledPlaceholder>
            )}
          </StyledValuesContainer>

          <StyledActionsContainer>
            {values?.length > 0 && (
              <StyledAction
                onClick={onClear}
                title={"Alles wissen"}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faXmark} size="sm" />
              </StyledAction>
            )}

            <FontAwesomeIcon icon={faCaretDown} size="sm" />
          </StyledActionsContainer>
        </StyledMultiSelect>
      )}
    />
  );
}

export default MultiSelectView;
