import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledAltIconButton } from "../../../styles/InputStyles";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  StyledDashboardNav,
  StyledNavButton,
  StyledMenu,
  StyledUserName,
} from "./DashboardNavStyles";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import useSearchLogsRouteConfig from "../../../routes/configs/pages/SearchLogsRouteConfig";

function DashboardNavView({ buttonRef, open, onLogoutClick }) {
  const authContext = useAuthContext();
  const searchLogsRouteConfig = useSearchLogsRouteConfig();

  return (
    <StyledDashboardNav>
      <StyledAltIconButton ref={buttonRef}>
        <FontAwesomeIcon icon={faBars} />
      </StyledAltIconButton>

      {open && (
        <StyledMenu>
          <StyledUserName>{authContext.auth.user?.name}</StyledUserName>

          <Link to={"/dashboard/users"}>
            <StyledNavButton>Gebruikers</StyledNavButton>
          </Link>

          <Link to={"/dashboard/places"}>
            <StyledNavButton>Plaatsen</StyledNavButton>
          </Link>

          {searchLogsRouteConfig.validator.validate() === true && (
            <Link to={"/dashboard/search_logs"}>
              <StyledNavButton>Zoekgeschiedenis</StyledNavButton>
            </Link>
          )}

          <StyledNavButton onClick={onLogoutClick}>Uitloggen</StyledNavButton>
        </StyledMenu>
      )}
    </StyledDashboardNav>
  );
}

export default DashboardNavView;
