import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  StyledChildrenWrapper,
  StyledHiddenLoader,
  StyledLoader,
} from "./HiddenLoaderStyles";

function HiddenLoaderView({ loading, children, style }) {
  return (
    <StyledHiddenLoader style={style}>
      <StyledChildrenWrapper loading={loading.toString()}>
        {children}
      </StyledChildrenWrapper>

      {loading && (
        <StyledLoader>
          <FontAwesomeIcon icon={faSpinner} spin size={"lg"} />
        </StyledLoader>
      )}
    </StyledHiddenLoader>
  );
}

export default HiddenLoaderView;
