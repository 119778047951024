import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledCheckbox } from "./CheckboxStyles";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function CheckboxView({ value, onChange }) {
  return (
    <StyledCheckbox onClick={() => onChange(!value)}>
      {value && <FontAwesomeIcon icon={faCheck} />}
    </StyledCheckbox>
  );
}

export default CheckboxView;
