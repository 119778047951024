import styled from "styled-components";

export const StyledAltButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary.lightest};
  color: ${(props) => props.theme.colors.on.primary};
`;

export const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 30px;
  height: 30px;
`;

export const StyledAltIconButton = styled(StyledIconButton)`
  background-color: ${(props) => props.theme.colors.primary.lightest};
  color: ${(props) => props.theme.colors.on.primary};
`;

export const StyledTextButton = styled.button`
  color: ${(props) => props.color};
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
  cursor: pointer;
  padding: 0;
  background-color: transparent;

  &:hover {
    color: ${(props) => props.theme.colors.accent.dark};
    background-color: transparent;
  }
`;
