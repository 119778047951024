import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelope,
  faPhone,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  StyledActions,
  StyledCloseButton,
  StyledContactDetail,
  StyledCountIndicator,
  StyledHeader,
  StyledOverlay,
  StyledPlaceInfoRequestsButton,
  StyledPlaceName,
  StyledRequest,
  StyledRequestsLayout,
  StyledUserName,
} from "./PlaceInfoRequestsButtonStyles";
import { StyledAltIconButton } from "../../styles/InputStyles";
import HiddenLoader from "../loader/hidden/HiddenLoader";
import { StyledFlexColumn, StyledFlexRow } from "../../styles/LayoutStyles";

function PlaceInfoRequestsButtonView({
  data,
  open,
  onButtonClick,
  onPhoneClick,
  onMailClick,
  onDeleteClick,
}) {
  return (
    <StyledPlaceInfoRequestsButton>
      <HiddenLoader groups={"place_info_requests_fetch"}>
        <StyledAltIconButton
          onClick={onButtonClick}
          disabled={data.requests.length <= 0}
        >
          <FontAwesomeIcon icon={faBell} />
        </StyledAltIconButton>
      </HiddenLoader>

      {data.requests.length > 0 && (
        <StyledCountIndicator>!</StyledCountIndicator>
      )}

      {open && data.requests.length > 0 && (
        <StyledOverlay>
          <StyledRequestsLayout>
            {data.requests.map((request) => (
              <StyledRequest key={request.id}>
                <StyledHeader>
                  <p>
                    <StyledUserName>{request.user.name}</StyledUserName> heeft
                    je verzocht om contact op te nemen over{" "}
                    <StyledUserName>{request.place.name}</StyledUserName>. U
                    kunt contact opnemen doormiddel van onderstaande gegevens.
                  </p>

                  <StyledActions>
                    <HiddenLoader
                      groups={`place_info_requests_delete_${request.id}`}
                    >
                      <StyledAltIconButton
                        onClick={() => onDeleteClick(request)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </StyledAltIconButton>
                    </HiddenLoader>
                  </StyledActions>
                </StyledHeader>

                <StyledFlexColumn>
                  {request.user.phone_number && (
                    <StyledFlexRow style={{ alignItems: "center" }}>
                      <StyledAltIconButton
                        onClick={() => onPhoneClick(request)}
                      >
                        <FontAwesomeIcon icon={faPhone} />
                      </StyledAltIconButton>

                      <StyledContactDetail>
                        {request.user.phone_number}
                      </StyledContactDetail>
                    </StyledFlexRow>
                  )}

                  {request.user.email && (
                    <StyledFlexRow style={{ alignItems: "center" }}>
                      <StyledAltIconButton onClick={() => onMailClick(request)}>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </StyledAltIconButton>

                      <StyledContactDetail>
                        {request.user.email}
                      </StyledContactDetail>
                    </StyledFlexRow>
                  )}
                </StyledFlexColumn>
              </StyledRequest>
            ))}
          </StyledRequestsLayout>

          <StyledCloseButton onClick={onButtonClick}>
            <FontAwesomeIcon icon={faXmark} />
          </StyledCloseButton>
        </StyledOverlay>
      )}
    </StyledPlaceInfoRequestsButton>
  );
}

export default PlaceInfoRequestsButtonView;
