import styled from "styled-components";
import { StyledAltButton } from "../../../styles/InputStyles";

export const StyledDashboardNav = styled.div`
  position: relative;
`;

export const StyledMenu = styled.nav`
  position: absolute;
  top: ${(props) => `calc(100% + ${props.theme.spacing.normal})`};
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary.darkest};
  box-shadow: 10px 10px 30px -10px rgba(0, 0, 0, 0.3);
  z-index: 1;
  border-radius: ${(props) => props.theme.radius.normal};
  border: 1px solid ${(props) => props.theme.colors.primary.lightest};
  padding: ${(props) => props.theme.spacing.small};
  gap: ${(props) => props.theme.spacing.small};
`;

export const StyledNavButton = styled(StyledAltButton)`
  background-color: ${(props) => props.theme.colors.primary.dark};
  width: 100%;
  text-align: left;
`;

export const StyledUserName = styled.p`
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.normal};
  display: none;
  margin-bottom: ${(props) => props.theme.spacing.normal};
  opacity: 0.33;
  text-align: center;

  @media (max-width: 525px) {
    display: block;
  }
`;
