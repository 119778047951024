/**
 * This method will validate the middlewares
 * @param {function} middlewares
 * @returns {boolean|{ to: string, replace: boolean }}
 */
function useMiddlewares(middlewares) {
  for (let i = 0; i < middlewares.length; i++) {
    const validation = middlewares[i].validate();
    if (validation !== true) {
      return middlewares[i].redirect();
    }
  }

  return true;
}

export default useMiddlewares;
