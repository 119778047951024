import { useRef } from "react";
import { useGoogleMapsContext } from "../../contexts/GoogleMapsContext";
import useCustomEffect from "../../hooks/useCustomEffect";

function GoogleMapCircle({ controller, center, radius }) {
  const { libraries } = useGoogleMapsContext();
  const circleRef = useRef(null);

  /**
   * UseEffect for when the component is unmounted
   */
  useCustomEffect(() => {
    return () => {
      if (circleRef.current) {
        circleRef.current.setMap(null);
      }
    };
  }, []);

  /**
   * UseEffect to update the map circle
   */
  useCustomEffect(() => {
    //Check if the map is loaded and the position is set
    if (!controller.map || !center || !radius) return null;

    //Clear old circle
    if (circleRef.current) {
      circleRef.current.setMap(null);
    }

    //Create new circle
    circleRef.current = new libraries.maps.Circle({
      strokeColor: "black",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "black",
      fillOpacity: 0.2,
      map: controller.map,
      center: center,
      radius: radius,
    });
  }, [controller.map, center, radius]);

  return "";
}

export default GoogleMapCircle;
