/**
 * @param {boolean|{to: string, replace: boolean}|function} validation
 * @returns {{ validate: function }}
 */
export function useRouteValidator(validation) {
  function validate() {
    return validation;
  }

  return { validate: typeof validation === "function" ? validation : validate };
}

export default useRouteValidator;
