import { useAuthContext } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import {
  StyledBody,
  StyledDashboard,
  StyledDashboardMain,
  StyledHeader,
  StyledHeaderLayout,
  StyledUserName,
} from "./DashboardLayoutStyles";
import { Outlet } from "react-router-dom";
import DashboardNav from "./nav/DashboardNav";
import PlaceInfoRequestsButton from "../../components/placeInfoRequestsButton/PlaceInfoRequestsButton";
import { StyledFlexRow } from "../../styles/LayoutStyles";

function DashboardLayoutView({ dashboardData, updateDashboardData }) {
  const authContext = useAuthContext();

  return (
    <StyledDashboard>
      <StyledHeader>
        <StyledHeaderLayout>
          <h1>Space Finder</h1>

          <StyledFlexRow style={{ alignItems: "center" }}>
            <StyledUserName>
              <FontAwesomeIcon icon={faUser} /> {authContext.auth.user?.name}
            </StyledUserName>

            <DashboardNav />

            <PlaceInfoRequestsButton />
          </StyledFlexRow>
        </StyledHeaderLayout>
      </StyledHeader>

      <StyledBody>
        <Outlet context={{ dashboardData, updateDashboardData }} />
      </StyledBody>
    </StyledDashboard>
  );
}

export default DashboardLayoutView;
