import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  StyledCloseButton,
  StyledOverlay,
  StyledPdfPreview,
  StyledPreview,
  StyledPreviewContainer,
} from "./FilePreviewStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FilePreviewView({ path, extension, onClose }) {
  return (
    <StyledOverlay onClick={onClose}>
      <StyledPreviewContainer>
        {(extension === "png" ||
          extension === "jpg" ||
          extension === "jpeg") && (
          <StyledPreview src={path} alt="Afbeelding voorbeeld" />
        )}

        {extension === "pdf" && (
          <StyledPdfPreview title="PDF Preview" src={path} />
        )}

        <StyledCloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </StyledCloseButton>
      </StyledPreviewContainer>
    </StyledOverlay>
  );
}

export default FilePreviewView;
