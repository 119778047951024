import { useState } from "react";
import SearchLogsPageView from "./SearchLogsPageView";
import { useLoadContext } from "../../../contexts/LoadContext";
import api from "../../../apis/api";
import useCustomEffect from "../../../hooks/useCustomEffect";

function SearchLogsPage() {
  const loadContext = useLoadContext();
  const [search, setSearch] = useState("");
  const [searchLogs, setSearchLogs] = useState([]);

  /**
   * UseEffect for initializing the component
   */
  useCustomEffect(() => {
    fetchSearchLogs();
  });

  /**
   * Fetch the search logs from the API
   */
  async function fetchSearchLogs() {
    const loadTag = loadContext.enableLoader("search_logs_page_fetch");

    try {
      const response = await api.get("search_logs");
      if (response.status === 200 && response.data?.success) {
        setSearchLogs(response.data.data);
      }
    } catch (error) {
    } finally {
      loadContext.disableLoader(loadTag);
    }
  }

  /**
   * This method will handle the search change
   * @param {Event} event
   */
  function onSearchChange(event) {
    setSearch(event.target.value);
  }

  /**
   * This method will return the filtered search logs
   * @returns {Array} The filtered search logs
   */
  function getFilteredSearchLogs() {
    return searchLogs.filter((log) => {
      if (log.user_name.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }

      if (log.search.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }

      if (log.created_at.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }

      return false;
    });
  }

  return (
    <SearchLogsPageView
      searchLogs={getFilteredSearchLogs()}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
}

export default SearchLogsPage;
